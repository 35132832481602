import { BrowserSystemOptions, Configuration, LogLevel } from '@azure/msal-browser';
import Config from '../Config/configService';
import logger from '../Logging/logService';
import AppError from 'utils/appError';
import Tenant from 'models/tenant';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const appClientId = Config.get('App.ClientId') as string;

const browserSystemOptions: BrowserSystemOptions = {
  loggerOptions: {
    loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
      if (containsPii) {
        return;
      }
      switch (level) {
        case LogLevel.Warning:
        case LogLevel.Error:
          logger.error(new AppError(message));

          return;
        case LogLevel.Info:
          logger.info(message);

          return;
        case LogLevel.Verbose:
          logger.debug(message);

          return;
      }
    },
    piiLoggingEnabled: false,
  },
  windowHashTimeout: 15000,
  iframeHashTimeout: 15000,
  loadFrameTimeout: 15000,
};

export const msalConfigCommon: Configuration = {
  auth: {
    clientId: appClientId,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: Config.getAppURL(),
    postLogoutRedirectUri: Config.getAppURL() + '/logout',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: browserSystemOptions,
};

export const getMsalConfigForTenant = (tenant: Tenant): Configuration => {
  return {
    auth: {
      clientId: appClientId,
      authority: `https://login.microsoftonline.com/${tenant.id}`,
      redirectUri: Config.getAppURL(),
      postLogoutRedirectUri: Config.getAppURL() + '/logout',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: browserSystemOptions,
  };
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const apiClientId = Config.get('Api.ClientId') as string;
export const svcClientId = Config.get('Svc.ClientId') as string;
export const entraIdClientId = Config.get('EntraID.ClientId') as string;

export const mergeScopes = (scopes1: string[], scopes2: string[]): string[] => {
  const newScopes = [...scopes1];
  newScopes.push(...scopes2);

  return newScopes;
};

/**
 * Add here the scopes to request when obtaining an access token for the basic login
 */
export const loginRequest = {
  scopes: ['User.Read'],
};

/**
 * Add here the scopes to request when obtaining an access token for user management
 */
export const userMgmtRequest = {
  scopes: ['User.Read', 'User.ReadBasic.All'],
};

/**
 * Add here the scopes to request when obtaining an access token for the API.
 */
export const apiRequest = {
  scopes: ['offline_access', `${apiClientId}/.default`],
};

/**
 * Azure management API scopes
 */
export const azureManagementRequest = {
  scopes: ['https://management.azure.com/user_impersonation'],
};

/**
 * Sharepoint integration scopes
 */
export const graphSharepointLibraryRequest = {
  scopes: ['Sites.Read.All', 'Files.ReadWrite.All'],
};

export const graphSharepointListRequest = {
  scopes: ['Sites.Read.All'],
};

export const graphSharepointPagesRequest = {
  scopes: ['Sites.Read.All'],
};

export const graphSharepointManageRequest = {
  scopes: ['Sites.Manage.All', 'Files.ReadWrite.All'],
};

export const graphSharepointSearchConsent = {
  scopes: ['Sites.Read.All', 'Files.Read.All'],
};

export const graphSharepointReportsRequest = {
  scopes: ['https://graph.microsoft.com/Files.ReadWrite.All'],
};

/*
 * Teams integration scopes
 */
export const graphTeamsChannelRequest = {
  scopes: ['ChannelMessage.Send'],
};

export const graphTeamsCollaborationRequest = {
  scopes: ['Chat.Create', 'Channel.Create', 'ChatMessage.Send', 'ChannelMessage.Send'],
};

export const graphTeamsMembersRequest = {
  scopes: ['TeamMember.Read.All', 'User.Read'],
};

export const graphTeamsTaskConsent = {
  scopes: ['TeamMember.Read.All', 'User.Read', 'Sites.Read.All', 'Files.ReadWrite.All'],
};

export const graphTeamsLibraryConsent = {
  scopes: ['TeamMember.Read.All', 'User.Read', 'Sites.Read.All', 'Files.ReadWrite.All', 'ChannelMessage.Send'],
};

/*
 * Outlook integration scopes
 */
export const graphOutlookTasksBasicRequest = {
  scopes: [
    'https://graph.microsoft.com/User.Read.All',
    'https://graph.microsoft.com/Group.Read.All',
    'https://graph.microsoft.com/Calendars.ReadWrite',
  ],
};

export const graphOutlookTasksAdvancedRequest = {
  scopes: [`${apiClientId}/.default`],
};

/*
 * Microsoft Entra ID integration scopes
 */
export const graphADConsentRequest = {
  scopes: ['https://graph.microsoft.com/User.Read.All', 'https://graph.microsoft.com/Group.Read.All'],
};

export const entraIdConsentRequest = {
  scopes: ['offline_access', `${entraIdClientId}/.default`],
};

/*
 * Security events
 */
export const graphSecurity = {
  scopes: ['SecurityEvents.Read.All'],
};

/*
 * Power Automate
 */
export const powerAutomateSharePointRequest = {
  scopes: ['https://graph.microsoft.com/Files.Read.All', 'https://graph.microsoft.com/Sites.Read.All'],
};

/*
 * Power BI
 */
export const powerBIServiceRequest = {
  scopes: ['openid', 'profile', 'email'],
};

export const powerBIClientRequest = {
  scopes: ['openid', 'profile', 'email', 'https://analysis.windows.net/powerbi/api/.default'],
};
   
