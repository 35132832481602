import {
  Callout,
  DetailsList,
  DirectionalHint,
  ICalloutContentStyles,
  IColumn,
  IDetailsList,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Image,
  ImageFit,
  IImageProps,
  Checkbox,
  Dropdown,
  IDropdownOption,
  ISelectableOption,
  IconButton,
  TooltipHost,
  Link,
  IDetailsRowProps,
  FontIcon,
  getTheme,
  Separator,
  ScrollablePane,
  ScrollbarVisibility,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import { SearchResult } from 'models/search/SearchResult';
import { FormEvent, forwardRef, Fragment, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalSearchResultItem } from './GlobalSearchResultItem';
import { GlobalSearchEmptyState, IGlobalSearchEmptyState } from './GlobalSearchEmptyState';
import { useHistory } from 'react-router-dom';
import Entity, { EntityTypes } from 'models/entity';
import { graphSharepointLibraryRequest } from 'services/Auth/authConfig';
import Config from 'services/Config/configService';
import { globalStackTokensGapExtraSmall, globalStackTokensGapSmall, globalTextStylesDisabled } from 'globalStyles';
import {
  freshdeskWidgetOpenTicket,
  freshdeskWidgetSetContactInfo,
  freshdeskWidgetSetSubject,
  freshdeskWidgetSetTranslations,
  getEntityIconName,
  getEntityTypeName,
} from 'globalFunctions';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { KeyValueTag } from 'components/Tags/KeyValueTag';
import { getUniqueTagIds } from 'utils/tagHelpers';
import { sortOnString } from 'utils/sorting';
import { getUniqueNormIds } from 'utils/standardHelpers';
import { LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import { SharepointSearch } from 'models/setting';
import { GlobalSearchModes } from './GlobalSearch';
import { navigateToExternalUrl } from 'utils/url';
import { graphGetDriveItem } from 'services/Graph/graphServiceDrive';
import AppError from 'utils/appError';
import { globalNavBarHeight } from 'globalConstants';
import { getGlobalFilters, globalFilterCount } from 'components/GlobalFilter/GlobalFilterHelper';
import { GlobalFilterGroupKeys } from 'components/GlobalFilter/GlobalFilterGroupKeys';

export interface IGlobalSearchResults {
  focus: () => void;
}

interface IGlobalSearchResultsProps {
  mode: GlobalSearchModes;
  results: SearchResult[];
  hidden: boolean;
  isSearching: boolean;
  isTyping: boolean;
  searchText: string | undefined;
  targetId: string;
  sharePointSearch: boolean;
  onSelectRecentSearch: (search: string) => void;
  onSelectType: (type: EntityTypes) => void;
  onSetSharePointSearch: (value: boolean) => void;
  onSetMode(mode: GlobalSearchModes, stopProcessing: boolean): void;
  onShowTask: (taskId: number) => void;
  onShowLink: (linkId: number) => void;
}

export const GlobalSearchResults = forwardRef(function GlobalSearchResults(props: IGlobalSearchResultsProps, ref) {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'dashboard']);
  const history = useHistory();
  const emptyStateRef = useRef<IGlobalSearchEmptyState | null>(null);
  const resultsRef = useRef<IDetailsList | null>(null);
  const [selectedType, setSelectedType] = useState<EntityTypes>(EntityTypes.NotSet);
  const [selectedStandards, setSelectedStandards] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [resultsDisplay, setResultsDisplay] = useState<SearchResult[]>([]);
  const [uniqueTagIds, setUniqueTagIds] = useState<number[]>([]);
  const [uniqueNormIds, setUniqueNormIds] = useState<number[]>([]);

  //constants
  const theme = getTheme();
  const clientWidth =
    (document.getElementById(props.targetId)?.clientWidth ?? 200) +
    (document.getElementById(props.targetId)?.offsetLeft ?? 0);
  const globalFilterEnabled = globalFilterCount(appContext, GlobalFilterGroupKeys.standard) > 0;

  const imagePropsEmptyResults: IImageProps = {
    src: `${Config.getImageURL()}/nosearchresults.png`,
    imageFit: ImageFit.contain,
    width: 64,
    height: 64,
    shouldFadeIn: false,
  };

  useImperativeHandle(
    ref,
    (): IGlobalSearchResults => {
      return {
        focus() {
          if (props.mode === GlobalSearchModes.FocusEmpty) {
            emptyStateRef.current?.focus();
          } else if (props.mode === GlobalSearchModes.FocusResult) {
            resultsRef.current?.focusIndex(0);
          }
        },
      };
    },
    [props.mode],
  );

  useEffect(() => {
    const applyFilter = (results: SearchResult[], standards: number[], tags: number[]): SearchResult[] => {
      let filteredResults = results;
      if (globalFilterEnabled) {
        const globalFilterStandards = getGlobalFilters(appContext, GlobalFilterGroupKeys.standard);
        filteredResults = filteredResults.filter((result) => {
          return result.normIds.some((s) => globalFilterStandards.includes(s.toString()));
        });
      } else {
        if (standards.length > 0) {
          filteredResults = filteredResults.filter((result) => {
            return result.normIds.some((s) => standards.includes(s));
          });
        }
      }

      if (tags.length > 0) {
        filteredResults = filteredResults.filter((result) => {
          return result.tagIds.some((tagId) => tags.includes(tagId));
        });
      }

      return filteredResults;
    };

    const results = applyFilter(props.results, selectedStandards, selectedTags);
    setResultsDisplay(results);
  }, [selectedStandards, selectedTags, props.results, globalFilterEnabled, appContext]);

  useEffect(() => {
    const tagIds = getUniqueTagIds(props.results);
    setUniqueTagIds(tagIds);
    const newSelTagIds = selectedTags.filter((tagId) => tagIds.includes(tagId));
    setSelectedTags(newSelTagIds);

    const normIds = getUniqueNormIds(props.results);
    setUniqueNormIds(normIds);
    const newSelNormIds = selectedStandards.filter((normId) => normIds.includes(normId));
    setSelectedStandards(newSelNormIds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.results]);

  const calloutStyle: Partial<ICalloutContentStyles> = {
    beakCurtain: {
      borderRadius: 6,
    },
    root: {
      paddingLeft: 20,
      paddingRight: 10,
      paddingTop: 20,
      paddingBottom: 20,
      minWidth: 350,
      width: appContext.isMobileView ? '97vw' : clientWidth,
      height: '75vh',
      maxHeight: '75vh',
      boxShadow: 'rgba(0, 0, 0, 0.18) 0px 10.4px 20.4px 0px, rgba(0, 0, 0, 0.18) 0px 10.2px 20.6px 0px',
    },
  };

  const columnsResult: IColumn[] = [
    {
      key: 'column1',
      name: 'Results',
      minWidth: 100,
      onRender: (item: SearchResult) => {
        return (
          <GlobalSearchResultItem
            key={item.id}
            result={item}
            highlight={props.searchText}
            navigateToEntity={navigateToEntity}
            clientWidth={clientWidth}
          />
        );
      },
    },
  ];

  const navigateToSharePointItem = async (result: SearchResult | undefined) => {
    try {
      if (!result) {
        return;
      }
      if (result.spOdataType !== '#microsoft.graph.driveItem') {
        if (!result.spWebUrl) {
          throw new AppError('spWebUrl is empty for SharePoint item');
        }
        navigateToExternalUrl(result.spWebUrl, undefined, '', true);
      } else {
        if (!result.spListId) {
          throw new AppError('spListId is empty for SharePoint item');
        }
        appContext.showContentLoader();
        const graphInterface = await appContext.getGraphInterface(graphSharepointLibraryRequest.scopes);
        const driveItem = await graphGetDriveItem(graphInterface.client, result.spListId, result.entityId);
        if (driveItem && driveItem.webUrl) {
          navigateToExternalUrl(driveItem.webUrl, undefined, '', true);
        } else {
          throw new AppError('No webUrl found for SharePoint item');
        }
      }
    } catch (err) {
      appContext.showNotification(t('translation:NotFound.Main'), true);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const navigateToEntity = (entity: Entity, result?: SearchResult) => {
    switch (entity.typeOfEntity) {
      case EntityTypes.Requirement:
        history.push(`/theme/${entity.entityId}`);
        break;
      case EntityTypes.Control:
        history.push(`/control/${entity.entityId}`);
        break;
      case EntityTypes.Task:
        props.onShowTask(entity.entityId);
        break;
      case EntityTypes.Risk:
        history.push(`/risk/${entity.entityId}`);
        break;
      case EntityTypes.Objective:
        history.push(`/objective/${entity.entityId}`);
        break;
      case EntityTypes.Process:
        history.push(`/process/${entity.entityId}`);
        break;
      case EntityTypes.KPI:
        history.push(`/kpi/${entity.entityId}`);
        break;
      case EntityTypes.TaskType:
        history.push(`/organization/forms?edit=${entity.entityId}`);
        break;
      case EntityTypes.Asset:
        history.push(`/asset/${entity.entityId}`);
        break;
      case EntityTypes.Link:
        props.onShowLink(entity.entityId);
        break;
      case EntityTypes.SharePointItem:
        navigateToSharePointItem(result);
        break;
      default:
        break;
    }

    console.log('navigateToEntity Close');
    onClose();
  };

  //
  // Render helpers
  //
  const getContent = () => {
    if (props.mode === GlobalSearchModes.FocusEmpty) {
      return (
        <GlobalSearchEmptyState
          ref={emptyStateRef}
          onSelectRecentSearch={props.onSelectRecentSearch}
          onSelectRecentItem={navigateToEntity}
        />
      );
    } else if (props.mode === GlobalSearchModes.FocusResult) {
      return (
        <Stack verticalFill tokens={globalStackTokensGapSmall}>
          <Stack.Item grow>{getResultList()}</Stack.Item>
          <Separator styles={{ root: { height: 1 } }} />
          <Stack tokens={globalStackTokensGapExtraSmall}>
            <Stack.Item>{getSharePointSearchInfo()}</Stack.Item>
            {!appContext.isMobileView && (
              <Stack.Item>
                <Text variant="small" styles={globalTextStylesDisabled}>
                  {t('translation:GlobalSearch.Feedback')}
                </Text>{' '}
                <Text variant="small">
                  <Link onClick={onFeedback}>{t('translation:GlobalSearch.FeedbackLink')}</Link>
                </Text>
              </Stack.Item>
            )}
          </Stack>
        </Stack>
      );
    }
  };

  const onFeedback = () => {
    freshdeskWidgetOpenTicket();
    freshdeskWidgetSetContactInfo(appContext.user.name, appContext.user.email);
    freshdeskWidgetSetTranslations();
    freshdeskWidgetSetSubject(t('translation:GlobalSearch.FeedbackSubject'));
  };

  const getSharePointSearchInfo = () => {
    const spSearchEnabled = appContext.globalDataCache.settings.get(SharepointSearch) as boolean;
    const isManager = hasUserFeature(appContext, FeatureTypes.GenericManager);

    if (spSearchEnabled) {
      return (
        <Stack.Item>
          <Checkbox
            checked={props.sharePointSearch}
            label={t('translation:GlobalSearch.ShowSharePointResults')}
            onChange={(ev, checked) => {
              props.onSetSharePointSearch(checked ?? false);
              setLocalStorageData(
                appContext,
                LocalStorageKeys.GlobalSearchSharePointSearch,
                checked ? 'true' : 'false',
              );
            }}
          />
        </Stack.Item>
      );
    } else if (isManager) {
      return (
        <Stack.Item>
          <Link onClick={() => navigateToIntegration()}>{t('translation:GlobalSearch.DisabledManager')}</Link>
        </Stack.Item>
      );
    } else {
      return (
        <Stack.Item>
          <Text>{t('translation:GlobalSearch.DisabledUser')}</Text>
        </Stack.Item>
      );
    }
  };

  const navigateToIntegration = () => {
    history.push({ pathname: '/admin/settings/integration' });
    console.log('navigateToIntegration Close');
    onClose();
  };

  const getFilter = () => {
    return (
      <Stack
        horizontal
        wrap
        tokens={globalStackTokensGapSmall}
        styles={{ root: { paddingRight: 10, paddingBottom: 10 } }}
        verticalAlign="center"
      >
        <Stack.Item>
          <Dropdown
            selectedKey={selectedType}
            options={getTypeOptions()}
            calloutProps={{ calloutMaxHeight: 400 }}
            onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
              if (option) {
                setSelectedType(option.key as EntityTypes);
                props.onSelectType(option.key as EntityTypes);
              }
            }}
            styles={{ dropdown: { minWidth: 125 } }}
            dropdownWidth={250}
            onRenderOption={onRenderTypeOption}
            disabled={props.isSearching || props.results.length === 0}
          />
        </Stack.Item>
        <Stack.Item>
          <Dropdown
            multiSelect
            selectedKeys={selectedStandards}
            disabled={props.isSearching || uniqueNormIds.length === 0 || globalFilterEnabled}
            options={getStandardOptions()}
            calloutProps={{ calloutMaxHeight: 400 }}
            placeholder={t('translation:GlobalSearch.Standards.All')}
            onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
              if (option) {
                const oldIds = selectedStandards;
                let newIds: number[] = [];
                if (option.selected !== undefined) {
                  newIds = option.selected
                    ? [...oldIds, option.key as number]
                    : oldIds.filter((key) => key !== option.key);
                }
                setSelectedStandards(newIds);
              }
            }}
            styles={{ dropdown: { minWidth: 125 } }}
            dropdownWidth={250}
            onRenderTitle={onRenderStandardsTitle}
          />
        </Stack.Item>
        <Stack.Item>
          <Dropdown
            multiSelect
            selectedKeys={selectedTags}
            disabled={props.isSearching || uniqueTagIds.length === 0}
            options={getTagOptions()}
            calloutProps={{ calloutMaxHeight: 400 }}
            onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
              if (option) {
                const oldIds = selectedTags;
                let newIds: number[] = [];
                if (option.selected !== undefined) {
                  newIds = option.selected
                    ? [...oldIds, option.key as number]
                    : oldIds.filter((key) => key !== option.key);
                }
                setSelectedTags(newIds);
              }
            }}
            placeholder={t('translation:GlobalSearch.Tags.NoSelection')}
            styles={{ dropdown: { minWidth: 125 } }}
            dropdownWidth={250}
            onRenderTitle={onRenderTagsTitle}
            onRenderOption={onRenderTagsItem}
          />
        </Stack.Item>
        {((selectedStandards.length > 0 && !globalFilterEnabled) ||
          selectedTags.length > 0 ||
          selectedType !== EntityTypes.NotSet) && (
          <Stack.Item>
            <TooltipHost content={t('translation:GlobalSearch.ClearFilter')}>
              <IconButton
                iconProps={{ iconName: 'ClearFilter' }}
                onClick={() => {
                  setSelectedStandards([]);
                  setSelectedTags([]);
                  if (selectedType !== EntityTypes.NotSet) {
                    props.onSelectType(EntityTypes.NotSet);
                  }
                  setSelectedType(EntityTypes.NotSet);
                }}
              />
            </TooltipHost>
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const onRenderTypeOption = (option?: IDropdownOption): JSX.Element | null => {
    if (!option) return null;

    return (
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        <FontIcon
          style={{ fontSize: '16px', color: theme.palette.themePrimary }}
          iconName={getEntityIconName(option.data)}
        />
        <Text>{option.text}</Text>
      </Stack>
    );
  };

  const onRenderStandardsTitle = (
    optionProps?: IDropdownOption[],
    defaultRender?: (optionProps?: IDropdownOption[]) => JSX.Element | null,
  ): JSX.Element | null => {
    if (globalFilterEnabled) {
      return (
        <Text>
          {t('translation:GlobalSearch.Standards.StandardFilterCount', {
            count: globalFilterCount(appContext, GlobalFilterGroupKeys.standard),
          })}
        </Text>
      );
    } else {
      return <Text>{t('translation:GlobalSearch.Standards.StandardFilterCount', { count: optionProps?.length })}</Text>;
    }
  };

  const onRenderTagsTitle = (
    optionProps?: IDropdownOption[],
    defaultRender?: (optionProps?: IDropdownOption[]) => JSX.Element | null,
  ): JSX.Element | null => {
    return <Text>{t('translation:GlobalSearch.Tags.TagFilterCount', { count: optionProps?.length })}</Text>;
  };

  const onRenderTagsItem = (
    optionProps?: ISelectableOption,
    defaultRender?: (optionProps?: ISelectableOption) => JSX.Element | null,
  ): JSX.Element | null => {
    return <KeyValueTag tag={optionProps?.data} />;
  };

  const getStandardOptions = (): IDropdownOption[] => {
    const norms = appContext.globalDataCache.norms.getItemsForId(uniqueNormIds);
    const options: IDropdownOption[] = [];

    const standardOptions = norms.map((norm) => {
      return {
        key: norm.normId,
        text: norm.name,
        data: norm,
      };
    });

    standardOptions.sort((a, b) => sortOnString(a.text, b.text));
    options.push(...standardOptions);

    return options;
  };

  const getTagOptions = (): IDropdownOption[] => {
    const tags = appContext.globalDataCache.tags.getItemsForId(uniqueTagIds);
    const options: IDropdownOption[] = [];

    const tagOptions = tags.map((tag) => {
      return {
        key: tag.tagId,
        text: tag.value(),
        data: tag,
      };
    });

    tagOptions.sort((a, b) => sortOnString(a.text, b.text));
    options.push(...tagOptions);

    return options;
  };

  const getTypeOptions = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [];

    options.push(getTypeOptionAll());
    if (hasUserFeature(appContext, FeatureTypes.Library)) {
      options.push(getTypeOption(EntityTypes.Link));
    }
    if (hasUserFeature(appContext, FeatureTypes.Processes)) {
      options.push(getTypeOption(EntityTypes.Process));
    }
    if (hasUserFeature(appContext, FeatureTypes.Objectives)) {
      options.push(getTypeOption(EntityTypes.Objective));
    }
    if (hasUserFeature(appContext, FeatureTypes.KPIsAndForms)) {
      options.push(getTypeOption(EntityTypes.KPI));
    }
    if (hasUserFeature(appContext, FeatureTypes.KPIsAndForms)) {
      options.push(getTypeOption(EntityTypes.TaskType));
    }
    if (hasUserFeature(appContext, FeatureTypes.Requirements)) {
      options.push(getTypeOption(EntityTypes.Requirement));
    }
    if (hasUserFeature(appContext, FeatureTypes.Assets)) {
      options.push(getTypeOption(EntityTypes.Asset));
    }
    if (hasUserFeature(appContext, FeatureTypes.Risks)) {
      options.push(getTypeOption(EntityTypes.Risk));
    }
    if (hasUserFeature(appContext, FeatureTypes.Controls)) {
      options.push(getTypeOption(EntityTypes.Control));
    }
    if (hasUserFeature(appContext, FeatureTypes.Tasks)) {
      options.push(getTypeOption(EntityTypes.Task));
    }

    return options;
  };

  const getTypeOptionAll = (): IDropdownOption => {
    return {
      key: EntityTypes.NotSet,
      text: t('translation:GlobalSearch.AllCategories'),
      data: EntityTypes.NotSet,
    };
  };

  const getTypeOption = (type: EntityTypes): IDropdownOption => {
    return {
      key: type,
      text: getEntityTypeName(type, t),
      data: type,
    };
  };

  const onKeyDownRecentItem = (ev: React.KeyboardEvent<HTMLElement>, result: SearchResult) => {
    switch (ev.key) {
      case 'Enter':
        const entity = new Entity(Number(result.entityId), result.entityType);
        navigateToEntity(entity);
        break;
      default:
        break;
    }
  };

  const onRenderRowResults = (
    rowProps?: IDetailsRowProps,
    defaultRender?: (rowProps?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (defaultRender && rowProps) {
      //make icon align left below the filter
      if (rowProps.cellStyleProps) {
        rowProps.cellStyleProps.cellLeftPadding = 0;
      }

      return (
        <Stack onKeyDown={(ev) => onKeyDownRecentItem(ev, rowProps.item as SearchResult)}>
          {defaultRender(rowProps)}
        </Stack>
      );
    } else {
      return null;
    }
  };

  const getResultList = () => {
    return (
      <Stack verticalFill>
        <Stack.Item>{getFilter()}</Stack.Item>
        {getResultListContent()}
      </Stack>
    );
  };

  const getResultListContent = () => {
    if (props.isSearching) {
      return (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
          <Spinner size={SpinnerSize.large} />
        </Stack>
      );
    } else if (props.isTyping) {
      return (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center" tokens={globalStackTokensGapSmall}>
          <Text>{'...'}</Text>
        </Stack>
      );
    } else if (resultsDisplay.length === 0) {
      return (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center" tokens={globalStackTokensGapSmall}>
          <Image {...imagePropsEmptyResults} />
          <Text>{t('translation:General.Search.NoData')}</Text>
        </Stack>
      );
    } else {
      return (
        <Stack.Item grow styles={{ root: { position: 'relative' } }}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <DetailsList
              componentRef={resultsRef}
              items={resultsDisplay}
              isHeaderVisible={false}
              columns={columnsResult}
              selectionMode={SelectionMode.none}
              onRenderRow={onRenderRowResults}
            />
          </ScrollablePane>
        </Stack.Item>
      );
    }
  };

  const onClose = () => {
    console.log('Close');
    if (props.mode === GlobalSearchModes.FocusEmpty) {
      props.onSetMode(GlobalSearchModes.UnFocusEmpty, true);
    } else if (props.mode === GlobalSearchModes.FocusResult) {
      props.onSetMode(GlobalSearchModes.UnFocusResult, true);
    }
  };

  const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    switch (ev.key) {
      case 'Escape':
        ev.stopPropagation();
        console.log('Escape');
        onClose();
        break;
      default:
        break;
    }
  };

  //
  // Main render
  //
  if (props.hidden) {
    return null;
  }

  return (
    <Fragment>
      <Callout
        id="global-search-callout"
        hidden={props.hidden}
        styles={calloutStyle}
        gapSpace={3}
        beakWidth={0}
        target={`#${props.targetId}`}
        onDismiss={(ev) => {
          ev?.stopPropagation();
          console.log('Dismiss results');
          onClose();
        }}
        directionalHint={appContext.isMobileView ? DirectionalHint.bottomCenter : DirectionalHint.bottomRightEdge}
        directionalHintFixed={true}
        onKeyDown={onKeyDown}
        preventDismissOnEvent={(ev) => {
          let prevent: boolean = false;

          //prevent dismiss on click in the navbar
          //this allows the user to select text in the search bar without closing the results and losing focus
          if (ev instanceof MouseEvent) {
            prevent = ev.y < globalNavBarHeight;
          }

          console.log('Prevent Dismiss', prevent);

          return prevent;
        }}
      >
        {getContent()}
      </Callout>
    </Fragment>
  );
});
