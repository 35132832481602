import React, { useContext, useEffect, useState } from 'react';
import Task, { TaskTypes } from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  DetailsListLayoutMode,
  DetailsRow,
  FontIcon,
  HoverCard,
  HoverCardType,
  IColumn,
  IDetailsListProps,
  IPlainCardProps,
  Link,
  Persona,
  PersonaSize,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
  TooltipHost,
  Selection,
  IObjectWithKey,
  ConstrainMode,
} from '@fluentui/react';
import {
  globalStackItemStylesPaddingSceneScroll,
  globalStackTokensGapSmall,
  globalTextStylesError,
} from 'globalStyles';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { sortOnBoolean, sortOnDate, sortOnString, sortOnTaskContext, sortOnTaskTags } from 'utils/sorting';
import AppContext from 'App/AppContext';
import { overflow } from 'utils/string';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import { toLocaleDateShort } from 'utils/datetime';
import Tag from 'models/tag';
import Entity from 'models/entity';
import { useHistory } from 'react-router-dom';
import { getLocalStorageData, LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import { getEntityUrl } from 'utils/url';
import ChecklistProgress from 'components/Checklist/ChecklistProgress';
import { ColumnCellItems } from 'components/Utils/ColumnCellItems';
import { filterColumnsFromLocalStorage } from 'utils/columns';
import SelectColumnsDialog from 'components/Dialogs/SelectColumnsDialog';
import { onRenderTaskContext } from './TaskRenderHelpers';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import CheckList from 'components/Checklist/Checklist';
import { FilterTaskGroupKeys } from '../Filter/FilterTaskGroupKeys';

interface ITasksListProps {
  tasks: Task[];
  filter: string[];
  isLoading: boolean;
  hideColumns?: string[];
  onTaskClick: (task: Task) => void;
  updateFilter?: (filter: string[]) => void;
  cacheKeySort: LocalStorageKeys;
  selectionMode?: SelectionMode;
  onChangeSelection?: (tasks: Task[]) => void;
  showColumnSelector?: boolean;
  hideColumnSelector?: () => void;
  selectionResetKey?: string;
}

const TaskList = (props: ITasksListProps) => {
  //load translation files for context items
  const { t } = useTranslation([
    'translation',
    'task',
    'tasks',
    'asset',
    'risk',
    'control',
    'theme',
    'objective',
    'process',
    'norms',
    'adminAuth',
  ]);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [tasksDisplay, setTasksDisplay] = useState<Task[]>([]);
  const [sortLoaded, setSortLoaded] = useState<boolean>(false);
  const [selectionResetKey, setSelectionResetKey] = useState<string>('set');

  const selection = new Selection({
    getKey: (item: Task) => {
      return item.taskId;
    },
    onSelectionChanged: () => {
      if (props.onChangeSelection) {
        const items = selection.getSelection() as Task[];
        props.onChangeSelection(items);
      }
    },
  });

  //
  // Effects
  //
  useEffect(() => {
    if (props.tasks && props.tasks.length > 0 && !sortLoaded) {
      //apply 1 time sort on changed tasks
      setTasksDisplay(loadSortFromLocalStorage(props.tasks));
    } else {
      setTasksDisplay(applyCurrentSort(props.tasks));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tasks]);

  useEffect(() => {
    setTasksDisplay(applyCurrentSort(props.tasks));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter]);

  useEffect(() => {
    if (props.selectionResetKey) setSelectionResetKey(props.selectionResetKey);
  }, [props.selectionResetKey]);

  //
  // Helpers
  //
  const setTagFilter = (tag: Tag, selectedFilter: string[]) => {
    let newFilter = [...selectedFilter];
    const filterId = FilterTaskGroupKeys.tag + tag.tagId;
    if (newFilter.indexOf(filterId) >= 0) {
      newFilter = newFilter.filter((f) => f !== filterId);
    } else {
      newFilter.push(filterId);
    }

    if (props.updateFilter) {
      props.updateFilter(newFilter);
    }
  };

  const onEntityClick = (item?: Entity) => {
    if (!item) {
      return;
    }

    let url = getEntityUrl(item);
    history.push(url);
  };

  //
  // Columns
  //
  const getColumns = (_selectedFilter: string[], applyFilter: boolean): IColumn[] => {
    let allColumns: IColumn[] = [
      {
        key: 'name',
        name: t('tasks:TabAllTasks.Fields.Name'),
        minWidth: 100,
        maxWidth: 250,
        isMultiline: true,
        isResizable: true,
        isSorted: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return;

          return (
            <TooltipHost content={item?.description}>
              <Text variant="medium">
                <Link underline onClick={() => props.onTaskClick(item)}>
                  {overflow(item?.name, 100)}
                </Link>
              </Text>
            </TooltipHost>
          );
        },
      },
      {
        key: 'assignee',
        name: t('tasks:TabAllTasks.Fields.Assignee'),
        minWidth: 30,
        maxWidth: 150,
        isResizable: true,
        isSorted: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return null;
          if (item.taskType === TaskTypes.Event && item.isSeries()) return null; //event series cannot be assigned

          let name: string = '';
          if (item.userId) {
            name = appContext.globalDataCache.users.get(item?.userId).name;
          }

          return (
            <TooltipHost content={name}>
              <Persona
                size={PersonaSize.size24}
                text={name}
                hidePersonaDetails={(column?.currentWidth ?? 0) < 100}
              ></Persona>
            </TooltipHost>
          );
        },
      },
      {
        key: 'status',
        name: t('tasks:TabAllTasks.Fields.Status'),
        minWidth: 80,
        maxWidth: 120,
        isResizable: true,
        isSorted: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return null;

          const status = appContext.globalDataCache.taskStates.get(item.taskStateId);

          return <Text variant="medium">{status ? status?.state : ''}</Text>;
        },
      },
      {
        key: 'checklist',
        name: t('task:Pivot.Checklist'),
        minWidth: 80,
        maxWidth: 200,
        isResizable: true,
        isSorted: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item || item.taskTypeId || item.checkList.items.length === 0) return null;

          const plainCardProps: IPlainCardProps = {
            onRenderPlainCard: onRenderChecklistHoverCard,
            renderData: item,
          };

          return (
            <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps} instantOpenOnClick={true}>
              <ChecklistProgress
                compact
                totalChecks={item.checkList.items.length}
                completedChecks={item.checkList.getCompletedCheckCount()}
                followUp={false}
              />
            </HoverCard>
          );
        },
      },
      {
        key: 'tags',
        name: t('tasks:TabAllTasks.Fields.Tags'),
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          return (
            <KeyValueTags
              tags={appContext.globalDataCache.tags.getItemsForId(item?.tagIds)}
              onClick={(tag) => setTagFilter(tag, _selectedFilter)}
            />
          );
        },
      },
      {
        key: 'start',
        name: t('tasks:TabAllTasks.Fields.Date'),
        minWidth: 70,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return null;

          return (
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <Text variant="small">{toLocaleDateShort(item.startDateTime)}</Text>
              {item.recurringPattern && item.recurringPattern.isActive && <FontIcon iconName="Sync" />}
            </Stack>
          );
        },
      },
      {
        key: 'completed',
        name: t('tasks:TabAllTasks.Fields.Completed'),
        minWidth: 70,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return null;

          return <Text variant="small">{toLocaleDateShort(item.completed)}</Text>;
        },
      },
      {
        key: 'deadline',
        name: t('tasks:TabAllTasks.Fields.Deadline'),
        minWidth: 70,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return null;
          if (item.taskType === TaskTypes.Event && item.isSeries()) return null; //event series cannot be assigned

          const deadline = item.getDeadline();
          let overDeadline = false;
          if (!item.completed) {
            overDeadline = item.isOverDeadline(deadline, new Date());
          }

          return (
            <Text variant="small" styles={overDeadline ? globalTextStylesError : undefined}>
              {toLocaleDateShort(deadline)}
            </Text>
          );
        },
      },
      {
        key: 'followUp',
        name: t('tasks:TabAllTasks.Fields.FollowUp'),
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          return <Checkbox checked={item?.followUp} />;
        },
      },
      {
        key: 'context',
        name: t('tasks:TaskView.Schedule.Columns.Context'),
        minWidth: 100,
        maxWidth: 300,
        isMultiline: true,
        isResizable: true,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return;
          let entityCount: number;
          let entity: Entity | undefined = undefined;

          entityCount = item.riskIds.length;
          entityCount += item.controlIds.length;
          entityCount += item.processIds.length;
          entityCount += item.objectiveIds.length;
          entityCount += item.assetIds.length;

          if (entityCount > 0) {
            const plainCardProps: IPlainCardProps = {
              onRenderPlainCard: onRenderTaskContext,
              renderData: item,
            };

            return (
              <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps} instantOpenOnClick={true}>
                <Text variant="small">
                  <Link
                    underline
                    onClick={() => {
                      onEntityClick(entity);
                    }}
                  >
                    {entityCount.toString() + ' items'}
                  </Link>
                </Text>
              </HoverCard>
            );
          } else {
            return null;
          }
        },
      },
      {
        key: 'standards',
        name: t('norms:List.ColumnHeader'),
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item || !item.normIds || item.normIds.length === 0) return;
          const norms = appContext.globalDataCache.norms.getItemsForId(item.normIds);

          return (
            <ColumnCellItems
              items={norms.map((n) => {
                return { id: n.normId, text: n.name };
              })}
              maxItems={3}
              showAll={undefined}
            />
          );
        },
      },
    ];

    if (hasUserFeature(appContext, FeatureTypes.RBAC)) {
      allColumns.push({
        key: 'permissions',
        name: t('adminAuth:AuthSchemaPicker.Label'),
        minWidth: 100,
        maxWidth: 350,
        isResizable: true,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return null;
          if (!item.authSchemaId) {
            return <Text>{t('adminAuth:AuthSchemaPicker.Placeholder')}</Text>;
          } else {
            const schema = appContext.globalDataCache.authSchemas.get(item.authSchemaId);

            return <Text>{schema.name ?? t('adminAuth:AuthSchemaPicker.Placeholder')}</Text>;
          }
        },
      });
    }

    if (props.hideColumns && props.hideColumns.length > 0) {
      allColumns = allColumns.filter((c) => props.hideColumns!.indexOf(c.key) === -1);
    }

    if (applyFilter) {
      return filterColumnsFromLocalStorage(appContext, LocalStorageKeys.TaskColumns, allColumns);
    } else {
      return allColumns;
    }
  };

  const [columns, setColumns] = useState<IColumn[]>(getColumns(props.filter, true));

  const onRenderChecklistHoverCard = (item: Task): JSX.Element => {
    return (
      <Stack styles={{ root: { width: '30vw', maxWidth: '95vw', height: 400, padding: 20 } }}>
        <CheckList
          allowEdit={false}
          allowFill={false}
          checkList={item.checkList}
          updateCheckList={() => {}}
          onComment={() => {}}
        />
      </Stack>
    );
  };

  //
  // Sorting
  //
  const applyCurrentSort = (tasks: Task[]): Task[] => {
    const col = getSortedColumn(columns);
    if (col) {
      tasks = copyAndSort(tasks, col.key, col.isSortedDescending);
    }

    return tasks;
  };

  const getSortedColumn = (columns: IColumn[]): IColumn | undefined => {
    return columns.find((col) => col.isSorted === true);
  };

  const loadSortFromLocalStorage = (tasks: Task[]): Task[] => {
    if (props.cacheKeySort) {
      setSortLoaded(true);
      const storedSort = getLocalStorageData(appContext, props.cacheKeySort);
      if (storedSort) {
        try {
          const values = storedSort.split(',');
          const colKey = values[0];
          const descending = values[1] === '1';

          const newColumns: IColumn[] = columns.slice();
          const currColumn: IColumn = newColumns.filter((currCol) => colKey === currCol.key)[0];

          for (let newCol of newColumns) {
            if (newCol === currColumn) {
              newCol.isSortedDescending = descending;
              newCol.isSorted = true;
            } else {
              newCol.isSorted = false;
              newCol.isSortedDescending = false;
            }
          }

          setColumns(newColumns);

          return copyAndSort(tasks, colKey, descending);
        } catch {
          //ignore
        }
      }
    }

    return tasks;
  };

  const saveSortToLocalStorage = (key: string, descending: boolean) => {
    if (props.cacheKeySort) {
      const values = [key, descending ? '1' : '0'];
      setLocalStorageData(appContext, props.cacheKeySort, values.join(','));
    }
  };

  const onColumnClick = (ev: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column?.key === currCol.key)[0];

    for (let newCol of newColumns) {
      if (newCol === currColumn) {
        newCol.isSortedDescending = !currColumn.isSortedDescending;
        newCol.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    }

    const newItems = copyAndSort(props.tasks, currColumn.key, currColumn.isSortedDescending);

    setColumns(newColumns);
    setTasksDisplay(newItems);
    saveSortToLocalStorage(currColumn.key, currColumn.isSortedDescending || false);
  };

  const copyAndSort = (items: Task[], columnKey: string, isSortedDescending?: boolean): Task[] => {
    if (columnKey === 'permissions') {
      return items.slice(0).sort((a: Task, b: Task) => {
        const cA = appContext.globalDataCache.authSchemas.get(a.authSchemaId);
        const cB = appContext.globalDataCache.authSchemas.get(b.authSchemaId);

        return (isSortedDescending ? -1 : 1) * sortOnString(cA.name, cB.name);
      });
    } else if (columnKey === 'checklist') {
      return items.slice(0).sort((a: Task, b: Task) => {
        const cA = a.checkList.getCompletedCheckCount();
        const cB = b.checkList.getCompletedCheckCount();

        return (isSortedDescending ? -1 : 1) * (cA - cB);
      });
    } else if (columnKey === 'followUp') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnBoolean(a.followUp, b.followUp);
      });
    } else if (columnKey === 'status') {
      return items.slice(0).sort((a: Task, b: Task) => {
        const cA = appContext.globalDataCache.taskStates.get(a.taskStateId).sortOrder;
        const cB = appContext.globalDataCache.taskStates.get(b.taskStateId).sortOrder;

        return (isSortedDescending ? -1 : 1) * (cA - cB);
      });
    } else if (columnKey === 'name') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnString(a.name, b.name);
      });
    } else if (columnKey === 'assignee') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnString(a.user?.name, b.user?.name);
      });
    } else if (columnKey === 'deadline') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnDate(a.getDeadline(), b.getDeadline());
      });
    } else if (columnKey === 'start') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnDate(a.startDateTime, b.startDateTime);
      });
    } else if (columnKey === 'completed') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnDate(a.completed, b.completed);
      });
    } else if (columnKey === 'context') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnTaskContext(a, b);
      });
    } else if (columnKey === 'tags') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnTaskTags(a, b);
      });
    }

    return items;
  };

  //
  // Render helpers
  //
  const onRenderRow: IDetailsListProps['onRenderRow'] = (rowProps) => {
    if (rowProps) {
      return (
        <span
          onDoubleClick={() => {
            if (props.onTaskClick) props.onTaskClick(rowProps.item);
          }}
        >
          <DetailsRow {...rowProps} />
        </span>
      );
    }

    return null;
  };

  //
  //
  // Main render
  //
  return (
    <Stack verticalFill>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        {!props.isLoading && tasksDisplay && tasksDisplay.length === 0 && (
          <Stack verticalFill horizontalAlign="center" verticalAlign="center">
            <Text>{t('translation:General.Notifications.NoItemsFilter')}</Text>
          </Stack>
        )}
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            compact
            items={tasksDisplay}
            columns={columns}
            setKey={selectionResetKey}
            isHeaderVisible={true}
            enableShimmer={props.isLoading}
            selection={selection as Selection<IObjectWithKey>}
            selectionMode={props.selectionMode || SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            onColumnHeaderClick={onColumnClick}
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
            onRenderRow={onRenderRow}
          />
        </ScrollablePane>
      </Stack.Item>
      {props.showColumnSelector && (
        <SelectColumnsDialog
          isOpen={props.showColumnSelector}
          onClose={() => {
            if (props.hideColumnSelector) props.hideColumnSelector();
          }}
          onSave={() => {
            if (props.hideColumnSelector) props.hideColumnSelector();
            const newColumns = getColumns(props.filter, true);
            setColumns(newColumns);
          }}
          columns={getColumns(props.filter, false)}
          cacheKey={LocalStorageKeys.TaskColumns}
        />
      )}
    </Stack>
  );
};

export default TaskList;
