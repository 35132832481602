import { Text, Stack, useTheme } from '@fluentui/react';
import { ReactNode, useContext, useState } from 'react';
import parse, { HTMLReactParserOptions, Element } from 'html-react-parser';
import AppContext from 'App/AppContext';
import {
  createBadgeJSX,
  createSpecialLinkJSX,
  getBadgeTextFromElement,
  getSpecialLinkElement,
  getSpecialLinkHrefFromElement,
  getSpecialLinkTextFromElement,
  isBadgeElement,
} from './RichTextBadgeHelper';
import { HoverEditIcon } from 'components/Utils/HoverEditIcon';
import { isEmpty } from 'utils/string';
import { globalTextStylesDisabled } from 'globalStyles';
import { stripParagraph } from 'utils/html';
import { IRichTextEditorSpecialLinkType } from './RichTextEditor';

export interface IRichTextReadOnlyProps {
  html?: string;
  placeholder?: string;
  height?: string | number;
  maxHeight?: string | number;
  onClick?: () => void;
  children?: ReactNode;
  onBadgeClick?: (badgeText: string) => void;
  onSpecialLinkClick?: (type: IRichTextEditorSpecialLinkType, text: string, href: string) => void;
  hideBorder?: boolean;
  stripParagraph?: boolean;
}

const RichTextReadOnly = (props: IRichTextReadOnlyProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const theme = useTheme();

  const onBadgeClick = (badgeText: string) => {
    if (props.onBadgeClick) {
      props.onBadgeClick(badgeText);
    }
  };

  const onSpecialLinkClick = (type: IRichTextEditorSpecialLinkType, text: string, href: string) => {
    if (props.onSpecialLinkClick) {
      props.onSpecialLinkClick(type, text, href);
    }
  };

  const onClick = () => {
    if (props.onClick) {
      setHover(false);
      props.onClick();
    }
  };

  //Add a specific onClick event to all badges
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      let node = domNode as Element;
      if (isBadgeElement(node)) {
        const badgeText = getBadgeTextFromElement(node);
        if (badgeText) {
          const newBadge = createBadgeJSX(badgeText.trim(), onBadgeClick);

          return newBadge;
        }
      }
      const specialLinkType = getSpecialLinkElement(node);
      if (specialLinkType !== IRichTextEditorSpecialLinkType.None) {
        const text = getSpecialLinkTextFromElement(node);
        const href = getSpecialLinkHrefFromElement(node);
        if (text && href) {
          const specialLink = createSpecialLinkJSX(specialLinkType, text, href, onSpecialLinkClick);

          return specialLink;
        }
      }
    },
  };

  const getValue = () => {
    if (isEmpty(props.html)) {
      return <Text styles={globalTextStylesDisabled}>{props.placeholder}</Text>;
    } else {
      let html = props.html;
      if (props.stripParagraph) {
        html = stripParagraph(props.html);
      }

      return parse(html ?? '', options);
    }
  };

  //
  // Main render
  //

  return (
    <Stack
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      onClick={() => {
        onClick();
      }}
      onMouseOver={() => {
        if (props.onClick) setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
      styles={{
        root: {
          minHeight: 32,
          border: props.hideBorder ? undefined : '1px solid grey',
          borderRadius: props.hideBorder ? undefined : '3px',
          paddingTop: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingBottom: '5px',
          color: props.html ? undefined : '#808080',
          background: props.onClick
            ? undefined
            : appContext.useDarkMode
            ? theme.palette.themeDarker
            : theme.palette.themeLighterAlt,
          '&:hover': props.onClick
            ? {
                cursor: 'text',
                'border-color': theme.palette.themeSecondary,
                background: theme.palette.neutralLighter,
                transition: 'all 0.3s ease',
              }
            : undefined,
        },
      }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item
          grow
          styles={{
            root: {
              height: props.height,
              maxHeight: props.maxHeight === -1 ? undefined : props.maxHeight || 300,
              overflowY: hover ? 'hidden' : 'scroll',
            },
          }}
        >
          <div style={{ borderWidth: '5px' }}>{getValue()}</div>
        </Stack.Item>
        <Stack.Item>{hover && <HoverEditIcon left={+10} top={-5} />}</Stack.Item>
      </Stack>

      <Stack.Item>{props.children}</Stack.Item>
    </Stack>
  );
};

export default RichTextReadOnly;
