import { useContext, useState } from 'react';
import { IconButton, Text, IImageProps, ImageFit, Image } from '@fluentui/react';
import { Stack, IStackStyles } from '@fluentui/react';
import { navigateToExternalUrl } from 'utils/url';
import AppContext from 'App/AppContext';
import AuthNavItem from './AuthNavItem';
import SettingsNavItem from './SettingsNavItem';
import HelpNavItem from './HelpNavItem';
import { navBarItemStyles } from './NavBarStyles';
import { darkTheme, lightTheme } from 'globalThemes';
import { useTranslation } from 'react-i18next';
import { globalFontBoldWeight, globalStackTokensGapSmall, o365Icon } from 'globalStyles';
import { globalAppName, globalNavBarHeight } from 'globalConstants';
import Tenant from 'models/tenant';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { GlobalSearch } from 'components/GlobalSearch/GlobalSearch';
import { GlobalFilter } from 'components/GlobalFilter/GlobalFilter';

interface INavBarProps {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  switchOrg: (tenant: Tenant) => Promise<void>;
  switchOrgUnit: (tenant: Tenant) => Promise<void>;
}

const GetStackStyles = (useDarkMode: boolean) => {
  const stackStyles: IStackStyles = {
    root: {
      background: useDarkMode ? darkTheme.palette?.neutralLight : lightTheme.palette?.themeLight,
      height: globalNavBarHeight,
    },
  };

  return stackStyles;
};

const NavBar = (props: INavBarProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const [isOpenAuthDialog, setIsOpenAuthDialog] = useState<boolean>(false);
  const [isOpenSettingsPanel, setIsOpenSettingsPanel] = useState<boolean>(false);
  const [logoUrl, setLogoUrl] = useState<string>('');

  const toggleAuthDialog = () => {
    setIsOpenAuthDialog(!isOpenAuthDialog);
  };

  const toggleSettingsPanel = () => {
    setIsOpenSettingsPanel(!isOpenSettingsPanel);
    setIsOpenAuthDialog(false);

    if (!isOpenSettingsPanel) {
      appContext.setHelpPanel(false);
    }
  };

  const toggleHelpPanel = () => {
    setIsOpenSettingsPanel(!appContext.isOpenHelpPanel ? false : isOpenSettingsPanel);
    setIsOpenAuthDialog(false);
    appContext.setHelpPanel(!appContext.isOpenHelpPanel);
  };

  const getTenantLogoProps = (logo: Blob): IImageProps => {
    let url = logoUrl;
    if (!url) {
      url = URL.createObjectURL(logo);
      setLogoUrl(url);
    }

    return {
      src: url,
      imageFit: ImageFit.contain,
      height: globalNavBarHeight,
    };
  };

  //
  // Main render
  //
  return (
    <Stack
      horizontal
      styles={GetStackStyles(appContext.useDarkMode)}
      tokens={globalStackTokensGapSmall}
      verticalAlign="center"
    >
      <Stack.Item styles={{ root: { width: '34%' } }}>
        <Stack horizontal verticalAlign="center" tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <IconButton
              styles={navBarItemStyles}
              iconProps={o365Icon}
              onClick={() => navigateToExternalUrl('https://office.com', '', '')}
            ></IconButton>
          </Stack.Item>
          {hasUserFeature(appContext, FeatureTypes.CustomLogo) && appContext.user.tenant.appLogo && (
            <Stack.Item
              styles={{
                root: {
                  maxHeight: globalNavBarHeight,
                  minWidth: globalNavBarHeight,
                  maxWidth: globalNavBarHeight * 3,
                  overflow: 'hidden',
                },
              }}
            >
              <Image {...getTenantLogoProps(appContext.user.tenant.appLogo)} />
            </Stack.Item>
          )}
          <Stack.Item>
            <Text nowrap styles={{ root: { fontWeight: globalFontBoldWeight, paddingLeft: 10 } }} variant="large">
              {t('translation:App.Title', { appName: globalAppName })}
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item styles={{ root: { width: '32%' } }}>
        <GlobalSearch />
      </Stack.Item>
      <Stack.Item styles={{ root: { width: '80px' } }}>
        <GlobalFilter />
      </Stack.Item>
      <Stack.Item styles={{ root: { width: 'calc(34% - 80px)' } }}>
        <Stack horizontal horizontalAlign="end" verticalAlign="center" tokens={globalStackTokensGapSmall}>
          {appContext.user.login.userLicensed && (
            <Stack.Item>
              <SettingsNavItem togglePanel={toggleSettingsPanel} isOpen={isOpenSettingsPanel}></SettingsNavItem>
            </Stack.Item>
          )}
          <Stack.Item>
            <HelpNavItem togglePanel={toggleHelpPanel} isOpen={appContext.isOpenHelpPanel}></HelpNavItem>
          </Stack.Item>
          <Stack.Item>
            <AuthNavItem
              login={props.login}
              logout={props.logout}
              toggleDialog={toggleAuthDialog}
              isOpen={isOpenAuthDialog}
              switchOrg={props.switchOrg}
              switchOrgUnit={props.switchOrgUnit}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default NavBar;
