import { useContext, useState } from 'react';
import {
  Callout,
  Stack,
  DirectionalHint,
  Text,
  Link,
  Checkbox,
  CommandBarButton,
  Separator,
  IconButton,
  Persona,
  PersonaSize,
  Spinner,
} from '@fluentui/react';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import Accordion from 'components/Accordion/Accordion';
import {
  globalStackStylesHeight100PaddingSmall,
  globalStackTokensGapSmall,
  filterICon,
  cancelIcon,
  globalSceneBarItemStyles,
} from 'globalStyles';
import User from 'models/user';
import UserPicker from 'components/Pickers/UserPicker';
import AppContext from 'App/AppContext';
import Tag from 'models/tag';
import KeyValueTagPicker from 'components/Pickers/KeyValueTagPicker';
import EntityPicker from 'components/Pickers/EntityPicker';
import { apiGetControl, apiGetControls } from 'services/Api/controlService';
import Entity, { EntityTypes } from 'models/entity';
import { apiGetTheme, apiGetThemes } from 'services/Api/themeService';
import { apiRequest } from 'services/Auth/authConfig';
import { KeyValueTag } from 'components/Tags/KeyValueTag';
import AppError from 'utils/appError';
import { IQuickFilterItemRenderer } from 'components/Filter/QuickFilter';
import Norm from 'models/norm';
import { FilterTaskGroupKeys } from './FilterTaskGroupKeys';
import { AuthSchema } from 'models/auth/authSchema';
import {
  convertAuthSchemaString,
  FeatureTypes,
  hasUserDataPermission,
  hasUserFeature,
} from 'services/Auth/featurePermissions';
import { TaskState } from 'models/tasks/taskHelperClasses';
import { controlIdSearchPrepend, themeIdSearchPrepend } from './FilterTasksHelper';
import { TaskTagFilterGroupAnd } from 'models/userSetting';
import { GlobalFilterGroupKeys } from 'components/GlobalFilter/GlobalFilterGroupKeys';
import {
  clearFilterKeepGlobalFilter,
  countGlobalFilters,
  globalFilterCount,
} from 'components/GlobalFilter/GlobalFilterHelper';
import { apiGetProcess, apiGetProcesses } from 'services/Api/processService';
import { apiGetObjective, apiGetObjectives } from 'services/Api/objectiveService';
import { apiGetAsset, apiGetAssets } from 'services/Api/assetService';
import { apiGetRisk, apiGetRisks } from 'services/Api/riskService';
import Theme from 'models/theme';
import Objective from 'models/objective/objective';
import { getEntity } from 'globalFunctions';
import Process from 'models/process/process';
import Control from 'models/control';
import Asset from 'models/asset/asset';
import Risk from 'models/risk';
import { AuthSchemaLineOperation } from 'models/auth/authSchemaLine';

interface IFilterControl {
  toggleDialog: () => void;
  isOpen: boolean;
  updateSelectedFilter: Function;
  selectedFilter: string[];
  hideClear?: boolean;
}

interface ICheckbox {
  key: string;
  name: string;
  value: string;
  label: string;
}

//
// Render the filter as a quick filter
// Each quick filter must be implemented here
// 1. When a quick filter cannot be rendered, return null so the filter is removed
// 2. When a quick filter needs to be loaded (e.g. control name), return a small <Spinner> while loading
//
export const QuickFilterTasksRenderer = (props: IQuickFilterItemRenderer): JSX.Element | null => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'tasks', 'adminAuth']);
  const [entity, setEntity] = useState<Entity | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const filterGroup = props.filter.split('$');
  const filterType = filterGroup[0] + '$';
  const filterValue = filterGroup[1];

  const loadEntity = async (entityId: number, type: EntityTypes) => {
    try {
      setIsLoading(true);

      //load Data
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let entity: Entity | undefined = undefined;

      switch (type) {
        case EntityTypes.Control:
          const control = await apiGetControl(entityId, false, false, accessToken, appContext.globalDataCache);
          if (control) {
            entity = new Entity();
            entity.entityId = control.controlId;
            entity.entityName = control.name;
            entity.entityCode = control.code;
            entity.typeOfEntity = type;
          }
          break;
        case EntityTypes.Requirement:
          const theme = await apiGetTheme(entityId, false, false, accessToken, appContext.globalDataCache);
          if (theme) {
            entity = new Entity();
            entity.entityId = theme.themeId;
            entity.entityName = theme.name;
            entity.entityCode = theme.code;
            entity.typeOfEntity = type;
          }
          break;
        case EntityTypes.Objective:
          const objective = await apiGetObjective(entityId, accessToken, appContext.globalDataCache);
          if (objective) {
            entity = new Entity();
            entity.entityId = objective.objectiveId;
            entity.entityName = objective.name;
            entity.entityCode = objective.code;
            entity.typeOfEntity = type;
          }
          break;
        case EntityTypes.Process:
          const process = await apiGetProcess(entityId, accessToken, appContext.globalDataCache);
          if (process) {
            entity = new Entity();
            entity.entityId = process.processId;
            entity.entityName = process.name;
            entity.entityCode = process.code;
            entity.typeOfEntity = type;
          }
          break;
        case EntityTypes.Asset:
          const asset = await apiGetAsset(entityId, accessToken, appContext.globalDataCache);
          if (asset) {
            entity = new Entity();
            entity.entityId = asset.assetId;
            entity.entityName = asset.name;
            entity.entityCode = asset.code;
            entity.typeOfEntity = type;
          }
          break;
        case EntityTypes.Risk:
          const risk = await apiGetRisk(entityId, accessToken, appContext.globalDataCache);
          if (risk && risk.risks.length > 0) {
            entity = new Entity();
            entity.entityId = risk.risks[0].riskId;
            entity.entityName = risk.risks[0].name;
            entity.entityCode = risk.risks[0].code;
            entity.typeOfEntity = type;
          }
          break;
        default:
          throw new AppError('not implemented');
      }

      setEntity(entity);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
      setHasLoaded(true);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  switch (filterType) {
    case FilterTaskGroupKeys.assignment:
      if (filterValue === 'notassigned') {
        return <Text>{t('tasks:Filter.NotAssignment')}</Text>;
      } else {
        if (!appContext.globalDataCache.users.has(filterValue)) return null;

        return <Persona size={PersonaSize.size24} text={appContext.globalDataCache.users.get(filterValue).name} />;
      }
    case FilterTaskGroupKeys.status:
      if (filterValue === 'open') {
        return <Text>{t('tasks:Filter.StatusOpen')}</Text>;
      } else {
        return <Text>{appContext.globalDataCache.taskStates.get(Number(filterValue)).state}</Text>;
      }
    case FilterTaskGroupKeys.shared:
      if (filterValue === 'everyone') {
        return <Persona size={PersonaSize.size24} text={t('adminAuth:AuthSchemaPicker.Placeholder')} />;
      } else {
        if (!appContext.globalDataCache.authSchemas.has(Number(filterValue))) return null;

        return (
          <Persona
            size={PersonaSize.size24}
            text={appContext.globalDataCache.authSchemas.get(Number(filterValue)).name}
          />
        );
      }
    case FilterTaskGroupKeys.startDate:
      let labelStartDate: string = '';
      switch (filterValue) {
        case 'startToday':
          labelStartDate = t('tasks:Filter.Today');
          break;
        case 'startNextWeek':
          labelStartDate = t('tasks:Filter.NextWeek');
          break;
        case 'startNextMonth':
          labelStartDate = t('tasks:Filter.NextMonth');
          break;
        case 'startNextQuarter':
          labelStartDate = t('tasks:Filter.NextQuarter');
          break;
      }

      return <Text>{labelStartDate}</Text>;
    case FilterTaskGroupKeys.deadline:
      let labelDeadline: string = '';
      switch (filterValue) {
        case 'deadlineTooLate':
          labelDeadline = t('tasks:Filter.TooLate');
          break;
        case 'deadlineToday':
          labelDeadline = t('tasks:Filter.Today');
          break;
        case 'deadlineThisWeek':
          labelDeadline = t('tasks:Filter.ThisWeek');
          break;
        case 'deadlineNextWeek':
          labelDeadline = t('tasks:Filter.NextWeek');
          break;
      }

      return <Text>{labelDeadline}</Text>;
    case FilterTaskGroupKeys.followUp:
      let labelFollowUp: string = '';
      switch (filterValue) {
        case 'true':
          labelFollowUp = t('tasks:Filter.FollowUp');
          break;
        case 'false':
          labelFollowUp = t('tasks:Filter.NotFollowUp');
          break;
      }

      return <Text>{labelFollowUp}</Text>;
    case FilterTaskGroupKeys.tag:
      const tagId = Number(filterValue);
      if (!appContext.globalDataCache.tags.has(tagId)) return null;
      const tag = appContext.globalDataCache.tags.get(tagId);

      return <KeyValueTag tag={tag} maxWidth={300} />;
    case FilterTaskGroupKeys.standard:
      if (appContext.globalFilter.some((f) => f.startsWith(GlobalFilterGroupKeys.standard))) {
        return null;
      }

      const normId = Number.parseInt(filterValue);
      if (!appContext.globalDataCache.norms.has(normId)) return null;
      const norm = appContext.globalDataCache.norms.get(normId);

      return <Text>{norm.name}</Text>;
    case FilterTaskGroupKeys.context:
      let entityId: number = 0;
      let entityType: EntityTypes = EntityTypes.NotSet;
      if (filterValue.startsWith(controlIdSearchPrepend)) {
        entityId = Number.parseInt(filterValue.substring(controlIdSearchPrepend.length));
        entityType = EntityTypes.Control;
      } else if (filterValue.startsWith(themeIdSearchPrepend)) {
        entityId = Number.parseInt(filterValue.substring(themeIdSearchPrepend.length));
        entityType = EntityTypes.Requirement;
      } else {
        entityId = Number.parseInt(filterValue.split('_')[1]);
        entityType = Number.parseInt(filterValue.split('_')[0]);
      }
      if (entityId) {
        if (entity) {
          return <Text>{entity.entityName}</Text>;
        } else if (!hasLoaded) {
          loadEntity(entityId, entityType);

          return <Spinner />;
        }
      }

      return null; //this will remove the filter
    default:
      return null;
  }
};

//
// Filter component
//
const FilterTasks = (props: IFilterControl) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'tasks', 'adminAuth']);
  const [allEntities, setAllEntities] = useState<Entity[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taskFilterAnd, setTaskFilterAnd] = useState<boolean>(
    appContext.globalDataCache.userSettings.get(TaskTagFilterGroupAnd) as boolean,
  );

  const followUpFilterList: ICheckbox[] = [
    {
      key: 'true',
      name: 'followUp',
      value: 'true',
      label: t('tasks:Filter.FollowUp'),
    },
    { key: 'false', name: 'notfollowUp', value: 'false', label: t('tasks:Filter.NotFollowUp') },
  ];

  const startDateList: ICheckbox[] = [
    { key: 'startToday', name: 'startToday', value: 'startToday', label: t('tasks:Filter.Today') },
    {
      key: 'startNextWeek',
      name: 'startNextWeek',
      value: 'startNextWeek',
      label: t('tasks:Filter.NextWeek'),
    },
    {
      key: 'startNextMonth',
      name: 'startNextMonth',
      value: 'startNextMonth',
      label: t('tasks:Filter.NextMonth'),
    },
    {
      key: 'startNextQuarter',
      name: 'startNextQuarter',
      value: 'startNextQuarter',
      label: t('tasks:Filter.NextQuarter'),
    },
  ];

  const deadlineList: ICheckbox[] = [
    {
      key: 'deadlineTooLate',
      name: 'deadlineTooLate',
      value: 'deadlineTooLate',
      label: t('tasks:Filter.TooLate'),
    },
    { key: 'deadlineToday', name: 'deadlineToday', value: 'deadlineToday', label: t('tasks:Filter.Today') },
    {
      key: 'deadlineThisWeek',
      name: 'deadlineThisWeek',
      value: 'deadlineThisWeek',
      label: t('tasks:Filter.ThisWeek'),
    },
    {
      key: 'deadlineNextWeek',
      name: 'deadlineNextWeek',
      value: 'deadlineNextWeek',
      label: t('tasks:Filter.NextWeek'),
    },
  ];

  const handleFilterClear = () => {
    clearFilter();
    props.toggleDialog();
  };

  const clearFilter = () => {
    const newFilter = clearFilterKeepGlobalFilter(appContext, props.selectedFilter, FilterTaskGroupKeys);
    props.updateSelectedFilter(newFilter);
  };

  const removeFilterContext = () => {
    let items: string[] = [...props.selectedFilter];
    items = items.filter((f) => !f.startsWith(FilterTaskGroupKeys.context));
    props.updateSelectedFilter(items);
  };

  const updateFilterContext = (key: string) => {
    let items: string[] = [...props.selectedFilter];
    items = items.filter(
      (f) =>
        !f.startsWith(FilterTaskGroupKeys.context + controlIdSearchPrepend) &&
        !f.startsWith(FilterTaskGroupKeys.context + themeIdSearchPrepend),
    );
    items.push(key);
    props.updateSelectedFilter(items);
  };

  const handleSelectChange = (key: string | string[]) => {
    let items: string[] = [...props.selectedFilter];

    if (key instanceof Array) {
      for (let idx = 0; idx < key.length; idx++) {
        const k = key[idx];
        if (k) {
          if (!props.selectedFilter.includes(k)) {
            items.push(k);
          } else {
            items = items.filter((i: string) => i !== k);
          }
        }
      }
    } else {
      if (!key) return;
      if (!props.selectedFilter.includes(key)) {
        items.push(key);
        props.updateSelectedFilter(items);
      } else {
        items = items.filter((i: string) => i !== key);
      }
    }

    props.updateSelectedFilter(items);
  };

  //
  // Functions to get specific values from the filter
  //
  const getUserIdFromFilter = (filter: string[]): string | undefined => {
    const assignment = filter.find(
      (f) => f.startsWith(FilterTaskGroupKeys.assignment) && f !== FilterTaskGroupKeys.assignment + 'notassigned',
    );

    return assignment?.split('$')[1];
  };

  const getTagsFromFilter = (filter: string[]): Tag[] => {
    const tags: Tag[] = [];
    filter.forEach((f) => {
      if (f.startsWith(FilterTaskGroupKeys.tag)) {
        const tagId = f.substring(FilterTaskGroupKeys.tag.length);
        const id = Number.parseInt(tagId);
        if (appContext.globalDataCache.tags.has(id)) {
          const tag = appContext.globalDataCache.tags.get(id);
          tags.push(tag);
        }
      }
    });

    return tags;
  };

  const getContextEntityFromFilter = (): Entity | undefined => {
    let entity: Entity | undefined = undefined;
    const filter = props.selectedFilter.find((f) => f.startsWith(FilterTaskGroupKeys.context));

    if (filter) {
      //control
      if (filter.startsWith(FilterTaskGroupKeys.context + controlIdSearchPrepend)) {
        entity = new Entity();
        entity.typeOfEntity = EntityTypes.Control;
        entity.entityId = Number.parseInt(
          filter.substring(FilterTaskGroupKeys.context.length + controlIdSearchPrepend.length),
        );
      } //requirement
      else if (filter.startsWith(FilterTaskGroupKeys.context + themeIdSearchPrepend)) {
        entity = new Entity();
        entity.typeOfEntity = EntityTypes.Requirement;
        entity.entityId = Number.parseInt(
          filter.substring(FilterTaskGroupKeys.context.length + themeIdSearchPrepend.length),
        );
      }
      //other
      else {
        const value = filter?.split('$')[1];
        const typeid = value?.split('_') ?? [];
        const type = Number(typeid[0]);
        const id = Number(typeid[1]);
        if (type && id && !isNaN(type) && !isNaN(id)) {
          entity = new Entity();
          entity.entityId = id;
          entity.typeOfEntity = type;
        }
      }
    }

    return entity;
  };

  const getSelectedFilterCount = (type: string): number => {
    if (props.selectedFilter.length > 0) {
      return props.selectedFilter.filter((f) => f.startsWith(type))?.length;
    }

    return 0;
  };

  const getTotalFilterCount = (filter: string[]): number => {
    const count = filter.filter((f) => !f.startsWith(FilterTaskGroupKeys.useAndForTagGroups)).length;

    return count - countGlobalFilters(appContext, filter, FilterTaskGroupKeys);
  };

  const loadDataForPicker = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      const _allThemesPromise = apiGetThemes(accessToken, appContext.globalDataCache);
      const _allProcessesPromise = apiGetProcesses(accessToken, appContext.globalDataCache);
      const _allObjectivesPromise = apiGetObjectives(accessToken, appContext.globalDataCache);
      const _allControlsPromise = apiGetControls(accessToken, appContext.globalDataCache);
      const _allAssetsPromise = apiGetAssets(accessToken, appContext.globalDataCache);
      const _allRisksPromise = apiGetRisks(accessToken, appContext.globalDataCache);

      const [_allThemesData, _allProcessesData, _allObjectivesData, _allControlsData, _allAssetsData, _allRisksData] =
        await Promise.allSettled([
          _allThemesPromise,
          _allProcessesPromise,
          _allObjectivesPromise,
          _allControlsPromise,
          _allAssetsPromise,
          _allRisksPromise,
        ]);

      let _allEntities: Entity[] = [];
      let errorMessage = '';

      if (_allThemesData.status === 'fulfilled') {
        _allThemesData.value.forEach((_theme: Theme) => {
          _allEntities.push(getEntity(_theme, _theme.getAutSchemaIds(appContext).join(',')));
        });
      } else {
        errorMessage += `${t('theme:Title')} : ${_allThemesData.reason}`;
      }

      if (_allObjectivesData.status === 'fulfilled') {
        _allObjectivesData.value.forEach((_objective: Objective) => {
          _allEntities.push(getEntity(_objective, _objective.authSchemaId?.toString()));
        });
      } else {
        errorMessage += `${t('objective:Title')} : ${_allObjectivesData.reason}`;
      }

      if (_allProcessesData.status === 'fulfilled') {
        _allProcessesData.value.forEach((_process: Process) => {
          _allEntities.push(getEntity(_process, _process.authSchemaId?.toString()));
        });
      } else {
        errorMessage += `${t('process:Title')} : ${_allProcessesData.reason}`;
      }

      if (_allControlsData.status === 'fulfilled') {
        _allControlsData.value.forEach((_control: Control) => {
          _allEntities.push(getEntity(_control, _control.getAutSchemaIds(appContext).join(',')));
        });
      } else {
        errorMessage += `${t('control:Title')} : ${_allControlsData.reason}`;
      }

      if (_allAssetsData.status === 'fulfilled') {
        _allAssetsData.value.forEach((_asset: Asset) => {
          _allEntities.push(getEntity(_asset, _asset.authSchemaId?.toString()));
        });
      } else {
        errorMessage += `${t('asset:Title')} : ${_allAssetsData.reason}`;
      }

      if (_allRisksData.status === 'fulfilled') {
        _allRisksData.value.risks.forEach((_risk: Risk) => {
          _allEntities.push(getEntity(_risk, _risk.authSchemaId?.toString()));
        });
      } else {
        errorMessage += `${t('process:Title')} : ${_allRisksData.reason}`;
      }

      if (errorMessage) appContext.setError(errorMessage);

      //filter out all items where the current user does not have update permissions
      _allEntities = _allEntities.filter((l) => {
        return hasUserDataPermission(appContext, convertAuthSchemaString(l.data), AuthSchemaLineOperation.Update);
      });

      setAllEntities(_allEntities);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  //
  // Main render
  //
  const filterCount = getTotalFilterCount(props.selectedFilter);

  return (
    <Stack horizontal verticalAlign="center">
      <CommandBarButton
        iconProps={filterICon}
        className="calloutTargetFilterButton"
        text={t('tasks:Filter.Button', { count: filterCount })}
        onClick={props.toggleDialog}
        styles={globalSceneBarItemStyles}
      />
      {filterCount > 0 && !props.hideClear && (
        <IconButton styles={globalSceneBarItemStyles} onClick={() => clearFilter()} iconProps={cancelIcon} />
      )}
      <Callout
        alignTargetEdge={true}
        gapSpace={0}
        target=".calloutTargetFilterButton"
        isBeakVisible={false}
        directionalHint={DirectionalHint.bottomLeftEdge}
        hidden={!props.isOpen}
        onDismiss={props.toggleDialog}
      >
        <Stack styles={globalStackStylesHeight100PaddingSmall} tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Stack horizontal horizontalAlign={'space-between'} styles={globalStackStylesHeight100PaddingSmall}>
              <Text variant={'mediumPlus'}>{t('tasks:Filter.Header')}</Text>
              <Link onClick={handleFilterClear} underline>
                {t('tasks:Filter.Clear')}
              </Link>
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{ root: { height: 500, width: 300, position: 'relative' } }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <Accordion
                disabled={globalFilterCount(appContext, GlobalFilterGroupKeys.standard) > 0}
                title={t('tasks:Filter.ISONorms', {
                  count: getSelectedFilterCount(FilterTaskGroupKeys.standard),
                })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  {appContext.globalDataCache.norms.items.map((norm: Norm) => (
                    <Checkbox
                      key={norm.normId}
                      label={norm.name}
                      checked={props.selectedFilter.includes(FilterTaskGroupKeys.standard + norm.normId.toString())}
                      onChange={() => handleSelectChange(FilterTaskGroupKeys.standard + norm.normId.toString())}
                    />
                  ))}
                </Stack>
              </Accordion>
              {hasUserFeature(appContext, FeatureTypes.RBAC) && (
                <Accordion
                  title={t('adminAuth:AuthSchemaPicker.Filter.Shared', {
                    count: getSelectedFilterCount(FilterTaskGroupKeys.shared),
                  })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                    <Checkbox
                      key={'everyone'}
                      label={t('adminAuth:AuthSchemaPicker.Placeholder')}
                      checked={props.selectedFilter.includes(FilterTaskGroupKeys.shared + 'everyone')}
                      onChange={() => {
                        handleSelectChange(FilterTaskGroupKeys.shared + 'everyone');
                      }}
                    />
                    <Separator styles={{ root: { height: 1 } }} />
                    {appContext.globalDataCache.authSchemas
                      .getItemsForMember(appContext.user.id)
                      .map((schema: AuthSchema) => (
                        <Checkbox
                          key={schema.authSchemaId}
                          label={schema.name}
                          checked={props.selectedFilter.includes(FilterTaskGroupKeys.shared + schema.authSchemaId)}
                          onChange={() => handleSelectChange(FilterTaskGroupKeys.shared + schema.authSchemaId)}
                        />
                      ))}
                  </Stack>
                </Accordion>
              )}
              <Accordion
                title={t('tasks:Filter.Assignment', {
                  count: getSelectedFilterCount(FilterTaskGroupKeys.assignment),
                })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  <Checkbox
                    key={'notassigned'}
                    label={t('tasks:Filter.NotAssignment')}
                    checked={props.selectedFilter.includes(FilterTaskGroupKeys.assignment + 'notassigned')}
                    onChange={() => {
                      const user = getUserIdFromFilter(props.selectedFilter);
                      handleSelectChange([FilterTaskGroupKeys.assignment + 'notassigned', user || '']);
                    }}
                  />
                  <Separator styles={{ root: { height: 1 } }} />
                  <UserPicker
                    users={[]}
                    selectedItemId={getUserIdFromFilter(props.selectedFilter)}
                    onSelect={(item: User | undefined, removedId?: string) => {
                      if (item) {
                        handleSelectChange(FilterTaskGroupKeys.assignment + item.id);
                      } else if (removedId) {
                        handleSelectChange(FilterTaskGroupKeys.assignment + removedId);
                      }
                    }}
                    disabled={props.selectedFilter.includes('notassigned')}
                    placeHolder={t('tasks:Filter.AssignmentPlaceholder')}
                  />
                </Stack>
              </Accordion>
              <Accordion
                title={t('tasks:Filter.Status', { count: getSelectedFilterCount(FilterTaskGroupKeys.status) })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  <Checkbox
                    key={'open'}
                    label={t('tasks:Filter.StatusOpen')}
                    checked={props.selectedFilter.includes(FilterTaskGroupKeys.status + 'open')}
                    onChange={() => handleSelectChange(FilterTaskGroupKeys.status + 'open')}
                  />
                  <Separator styles={{ root: { height: 1 } }} />
                  {appContext.globalDataCache.taskStates.items.map((taskState: TaskState) => (
                    <Checkbox
                      key={taskState.taskStateId}
                      label={taskState.state}
                      checked={props.selectedFilter.includes(
                        FilterTaskGroupKeys.status + taskState.taskStateId.toString(),
                      )}
                      onChange={() => handleSelectChange(FilterTaskGroupKeys.status + taskState.taskStateId.toString())}
                    />
                  ))}
                </Stack>
              </Accordion>
              <Accordion
                title={t('tasks:Filter.StartDate', { count: getSelectedFilterCount(FilterTaskGroupKeys.startDate) })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  {startDateList.map((startDate: ICheckbox) => (
                    <Checkbox
                      key={startDate.key}
                      label={startDate.label}
                      checked={props.selectedFilter.includes(FilterTaskGroupKeys.startDate + startDate.key)}
                      onChange={() => handleSelectChange(FilterTaskGroupKeys.startDate + startDate.key)}
                    />
                  ))}
                </Stack>
              </Accordion>
              <Accordion
                title={t('tasks:Filter.Deadline', { count: getSelectedFilterCount(FilterTaskGroupKeys.deadline) })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  {deadlineList.map((deadline: ICheckbox) => (
                    <Checkbox
                      key={deadline.key}
                      label={deadline.label}
                      checked={props.selectedFilter.includes(FilterTaskGroupKeys.deadline + deadline.key)}
                      onChange={() => handleSelectChange(FilterTaskGroupKeys.deadline + deadline.key)}
                    />
                  ))}
                </Stack>
              </Accordion>
              <Accordion
                title={t('tasks:Filter.FollowUpTitle', {
                  count: getSelectedFilterCount(FilterTaskGroupKeys.followUp),
                })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  {followUpFilterList.map((item: ICheckbox) => (
                    <Checkbox
                      key={item.key}
                      label={item.label}
                      checked={props.selectedFilter.includes(FilterTaskGroupKeys.followUp + item.value)}
                      onChange={() => handleSelectChange(FilterTaskGroupKeys.followUp + item.value)}
                    />
                  ))}
                </Stack>
              </Accordion>
              <Accordion title={t('tasks:Filter.Tags', { count: getSelectedFilterCount(FilterTaskGroupKeys.tag) })}>
                <Stack tokens={globalStackTokensGapSmall} styles={{ root: { padding: 10, height: 200 } }}>
                  <KeyValueTagPicker
                    maxTagWidth={250}
                    selectedTags={getTagsFromFilter(props.selectedFilter)}
                    onAdd={(item: Tag) => handleSelectChange(FilterTaskGroupKeys.tag + item.tagId.toString())}
                    onRemove={(item: Tag) => handleSelectChange(FilterTaskGroupKeys.tag + item.tagId.toString())}
                  />
                  <Checkbox
                    label={t('tasks:Filter.TagGroupAnd')}
                    checked={taskFilterAnd}
                    onChange={(ev, checked) => {
                      setTaskFilterAnd(checked ?? false);
                      appContext.globalDataCache.userSettings.set(TaskTagFilterGroupAnd, checked ?? false);
                      //when user changes this, remove the value from the filter
                      const newFilter = [...props.selectedFilter];
                      const idx = newFilter.findIndex((n) => n.startsWith(FilterTaskGroupKeys.useAndForTagGroups));
                      if (idx >= 0) newFilter.splice(idx, 1);
                      //update the filter and save the user setting
                      props.updateSelectedFilter(newFilter);
                      appContext.globalDataCache.userSettings.save(TaskTagFilterGroupAnd, checked ?? false); //async so set first
                    }}
                  />
                </Stack>
              </Accordion>
              <Accordion
                title={t('tasks:Filter.Context', { count: getSelectedFilterCount(FilterTaskGroupKeys.context) })}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  <EntityPicker
                    suggestionLimit={4}
                    entities={allEntities}
                    selectedEntity={getContextEntityFromFilter()}
                    addSelectedEntity={(item) => {
                      switch (item.typeOfEntity) {
                        case EntityTypes.Control:
                          updateFilterContext(FilterTaskGroupKeys.context + controlIdSearchPrepend + item.entityId);
                          break;
                        case EntityTypes.Requirement:
                          updateFilterContext(FilterTaskGroupKeys.context + themeIdSearchPrepend + item.entityId);
                          break;
                        default:
                          updateFilterContext(
                            `${FilterTaskGroupKeys.context}${item.typeOfEntity as number}_${item.entityId}`,
                          );
                          break;
                      }
                    }}
                    loadData={loadDataForPicker}
                    isLoading={isLoading}
                    showHeader={false}
                    setSearchTextToSelectedEntity={true}
                    clearSearchText={() => {
                      removeFilterContext();
                    }}
                    isOnPanel={false}
                  />
                </Stack>
              </Accordion>
            </ScrollablePane>
          </Stack.Item>
        </Stack>
      </Callout>
    </Stack>
  );
};

export default FilterTasks;
