import { IAppContext } from 'App/AppContext';
import { SubscriptionTypes } from 'utils/subscription';
import { appRoles, appRoleSets, appRoleSetTypes } from './appRoles';
import { PermissionTypes } from 'models/auth/rolePermission';
import { AuthSchemaLineOperation } from 'models/auth/authSchemaLine';
import Control from 'models/control';
import Theme from 'models/theme';

export enum FeatureTypes {
  OrgUnits = 1,
  Objectives = 2,
  KBTeams = 3,
  Automation = 4, //power automate flows, azure defender integration
  Dashboard = 5,
  DeleteStandardElement = 6,
  TaskChangeCompletionDate = 7,
  CustomLogo = 9,
  Assets = 10,
  SupplyChain = 11,
  Events = 12, //creation and usage of event templates
  KPIsAndForms = 13, //management of KPIs and Forms
  Approvals = 14,
  TaskForm = 15, //show form at task level
  Organization = 16,
  Requirements = 17,
  Risks = 18,
  Controls = 19,
  AnnualPlan = 20,
  Tasks = 21,
  Library = 22,
  Standards = 23,
  Audit = 24,
  PowerBI = 25,
  KPIAlerts = 26,
  RBAC = 27,
  Processes = 28,
  Admin = 50,
  AdminAuth = 51,
  AdminSubscription = 52,
  AdminOrgUnits = 53,
  AdminPermissions = 54,
  PartnerAdmin = 30,
  TenantAdmin = 300,
  TenantManagement = 301,
  TenantISOAdmin = 302,
  TenantDeleteISOStandardElement = 303,
  GenericManager = 500,
  PreviewLanguages = 1001,
  PreviewFeatures = 1002,
}

// Mapping to determine which features are available for subscription types
// Empty array means available for all
const subscriptionTypeFeatureMapping: Record<Partial<FeatureTypes>, SubscriptionTypes[]> = {
  [FeatureTypes.OrgUnits]: [SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.KBTeams]: [SubscriptionTypes.Business, SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.DeleteStandardElement]: [],
  [FeatureTypes.PreviewLanguages]: [SubscriptionTypes.Internal, SubscriptionTypes.Partner],
  [FeatureTypes.PreviewFeatures]: [SubscriptionTypes.Internal],
  [FeatureTypes.Dashboard]: [],
  [FeatureTypes.AdminAuth]: [],
  [FeatureTypes.AdminPermissions]: [],
  [FeatureTypes.AdminSubscription]: [],
  [FeatureTypes.AdminOrgUnits]: [SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.TenantAdmin]: [],
  [FeatureTypes.TenantManagement]: [],
  [FeatureTypes.TenantISOAdmin]: [],
  [FeatureTypes.GenericManager]: [],
  [FeatureTypes.TaskChangeCompletionDate]: [],
  [FeatureTypes.TenantDeleteISOStandardElement]: [],
  [FeatureTypes.CustomLogo]: [SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.Assets]: [],
  [FeatureTypes.SupplyChain]: [SubscriptionTypes.Business, SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.PartnerAdmin]: [SubscriptionTypes.Internal, SubscriptionTypes.Partner],
  [FeatureTypes.Processes]: [],
  [FeatureTypes.Objectives]: [],
  [FeatureTypes.Automation]: [SubscriptionTypes.Business, SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.Events]: [SubscriptionTypes.Business, SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.KPIsAndForms]: [SubscriptionTypes.Business, SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.TaskForm]: [SubscriptionTypes.Business, SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.Approvals]: [SubscriptionTypes.Business, SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.Organization]: [],
  [FeatureTypes.Requirements]: [],
  [FeatureTypes.Risks]: [],
  [FeatureTypes.Controls]: [],
  [FeatureTypes.AnnualPlan]: [],
  [FeatureTypes.Tasks]: [],
  [FeatureTypes.Library]: [],
  [FeatureTypes.Standards]: [],
  [FeatureTypes.Admin]: [],
  [FeatureTypes.Audit]: [],
  [FeatureTypes.PowerBI]: [
    SubscriptionTypes.Business,
    SubscriptionTypes.Premium,
    SubscriptionTypes.Internal,
    SubscriptionTypes.Partner,
  ],
  [FeatureTypes.RBAC]: [
    SubscriptionTypes.Business,
    SubscriptionTypes.Premium,
    SubscriptionTypes.Internal,
    SubscriptionTypes.Partner,
  ],
  [FeatureTypes.KPIAlerts]: [SubscriptionTypes.Premium, SubscriptionTypes.Partner],
};

// Mapping of which features are available for each role
// Empty array means available for all
const roleFeatureMapping: Record<FeatureTypes, appRoles[]> = {
  [FeatureTypes.OrgUnits]: [],
  [FeatureTypes.Objectives]: [],
  [FeatureTypes.Processes]: [],
  [FeatureTypes.KBTeams]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.DeleteStandardElement]: appRoleSets[appRoleSetTypes.Admins],
  [FeatureTypes.PreviewLanguages]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.PreviewFeatures]: [],
  [FeatureTypes.Dashboard]: appRoleSets[appRoleSetTypes.Users],
  [FeatureTypes.AdminAuth]: [...appRoleSets[appRoleSetTypes.Admins], appRoles.LicenseManager],
  [FeatureTypes.AdminPermissions]: [...appRoleSets[appRoleSetTypes.Admins]],
  [FeatureTypes.AdminSubscription]: [...appRoleSets[appRoleSetTypes.Admins], appRoles.LicenseManager],
  [FeatureTypes.AdminOrgUnits]: appRoleSets[appRoleSetTypes.Admins],
  [FeatureTypes.TenantAdmin]: appRoleSets[appRoleSetTypes.TenantAdmins],
  [FeatureTypes.TenantManagement]: [appRoles.TenantGlobalAdmin],
  [FeatureTypes.TenantISOAdmin]: appRoleSets[appRoleSetTypes.TenantAdmins],
  [FeatureTypes.GenericManager]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.TaskChangeCompletionDate]: appRoleSets[appRoleSetTypes.Admins],
  [FeatureTypes.TenantDeleteISOStandardElement]: [appRoles.TenantGlobalAdmin],
  [FeatureTypes.CustomLogo]: [],
  [FeatureTypes.Assets]: [],
  [FeatureTypes.SupplyChain]: [],
  [FeatureTypes.PartnerAdmin]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.Automation]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.KPIsAndForms]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.TaskForm]: [],
  [FeatureTypes.Events]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.Approvals]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.Organization]: [],
  [FeatureTypes.Requirements]: [],
  [FeatureTypes.Risks]: [],
  [FeatureTypes.Controls]: [],
  [FeatureTypes.AnnualPlan]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.Tasks]: [],
  [FeatureTypes.Library]: [],
  [FeatureTypes.Standards]: [],
  [FeatureTypes.Admin]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.Audit]: [],
  [FeatureTypes.PowerBI]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.RBAC]: [],
  [FeatureTypes.KPIAlerts]: appRoleSets[appRoleSetTypes.Managers],
};

// Mapping to determine which features are available for subscription types
// Empty array means available for all
const permissionFeatureMapping: Record<Partial<FeatureTypes>, PermissionTypes[]> = {
  [FeatureTypes.OrgUnits]: [],
  [FeatureTypes.KBTeams]: [PermissionTypes.ModuleLibrary],
  [FeatureTypes.DeleteStandardElement]: [],
  [FeatureTypes.PreviewLanguages]: [],
  [FeatureTypes.PreviewFeatures]: [],
  [FeatureTypes.Dashboard]: [],
  [FeatureTypes.AdminAuth]: [],
  [FeatureTypes.AdminPermissions]: [],
  [FeatureTypes.AdminSubscription]: [],
  [FeatureTypes.AdminOrgUnits]: [],
  [FeatureTypes.TenantAdmin]: [],
  [FeatureTypes.TenantManagement]: [],
  [FeatureTypes.TenantISOAdmin]: [],
  [FeatureTypes.GenericManager]: [],
  [FeatureTypes.TaskChangeCompletionDate]: [],
  [FeatureTypes.TenantDeleteISOStandardElement]: [],
  [FeatureTypes.CustomLogo]: [],
  [FeatureTypes.Assets]: [PermissionTypes.ModuleAssets],
  [FeatureTypes.SupplyChain]: [PermissionTypes.ModuleAssets],
  [FeatureTypes.PartnerAdmin]: [PermissionTypes.ModulePartner],
  [FeatureTypes.Processes]: [PermissionTypes.ModuleOrg],
  [FeatureTypes.Objectives]: [PermissionTypes.ModuleOrg],
  [FeatureTypes.Automation]: [],
  [FeatureTypes.Events]: [],
  [FeatureTypes.KPIsAndForms]: [PermissionTypes.ModuleOrg],
  [FeatureTypes.TaskForm]: [],
  [FeatureTypes.Approvals]: [],
  [FeatureTypes.Organization]: [PermissionTypes.ModuleOrg],
  [FeatureTypes.Requirements]: [PermissionTypes.ModuleRequirements],
  [FeatureTypes.Risks]: [PermissionTypes.ModuleRisks],
  [FeatureTypes.Controls]: [PermissionTypes.ModuleControls],
  [FeatureTypes.AnnualPlan]: [PermissionTypes.ModuleAnnualPlan],
  [FeatureTypes.Tasks]: [PermissionTypes.ModuleTasks],
  [FeatureTypes.Library]: [PermissionTypes.ModuleLibrary],
  [FeatureTypes.Standards]: [PermissionTypes.ModuleStandards],
  [FeatureTypes.Admin]: [PermissionTypes.ModuleAdmin],
  [FeatureTypes.Audit]: [],
  [FeatureTypes.PowerBI]: [],
  [FeatureTypes.RBAC]: [],
  [FeatureTypes.KPIAlerts]: [],
};

export const hasSubscriptionFeature = (type: SubscriptionTypes, feature: FeatureTypes): boolean => {
  if (type === SubscriptionTypes.Internal) return true;
  if (type === SubscriptionTypes.Custom) return false;
  if (type === SubscriptionTypes.Container) return false;

  const supportedTypes = subscriptionTypeFeatureMapping[feature];
  if (supportedTypes.length === 0 || supportedTypes.indexOf(type) >= 0) {
    return true;
  }

  return false;
};

const hasRoleFeature = (roles: number[] | undefined, feature: FeatureTypes): boolean => {
  const requiredRoles = roleFeatureMapping[feature];
  if (requiredRoles.length === 0) return true;

  if (!roles || roles.length === 0) {
    if (requiredRoles.includes(appRoles.User)) {
      return true;
    }
  } else {
    for (let idx = 0; idx < requiredRoles.length; idx++) {
      if (roles.includes(requiredRoles[idx])) {
        return true;
      }
    }
  }

  return false;
};

const hasPermissionFeature = (
  roles: number[] | undefined,
  permissions: number[] | undefined,
  feature: FeatureTypes,
): boolean => {
  //The admin role has all permissions
  if (roles && roles.length > 0 && hasAdminRole(roles)) {
    return true;
  }

  const requiredPermissions = permissionFeatureMapping[feature];
  if (requiredPermissions.length === 0) return true;
  
  //check permissions
  if (!permissions || permissions.length === 0) {
    return false;
  } else {
    for (let idx = 0; idx < requiredPermissions.length; idx++) {
      if (permissions.includes(requiredPermissions[idx])) {
        return true;
      }
    }
  }

  return false;
};

const hasAdminRole = (roles: number[] | undefined): boolean => {
  if (
    roles &&
    roles.length > 0 &&
    (roles.includes(appRoles.Admin) ||
      roles.includes(appRoles.OrgAdmin) ||
      roles.includes(appRoles.TenantGlobalAdmin) ||
      roles.includes(appRoles.TenantISOAdmin))
  ) {
    return true;
  }

  return false;
};

export const hasUserFeatureGenericManager = (appContext: IAppContext): boolean => {
  return hasRoleFeature(appContext.user.login.userSystemRoles, FeatureTypes.GenericManager);
};

export const hasUserFeature = (
  appContext: IAppContext,
  feature: FeatureTypes,
  permission?: PermissionTypes,
): boolean => {
  if (!hasSubscriptionFeature(appContext.user.login.subscriptionType, feature)) return false;
  if (!hasRoleFeature(appContext.user.login.userSystemRoles, feature)) return false;
  if (!hasPermissionFeature(appContext.user.login.userSystemRoles, appContext.user.login.permissions, feature))
    return false;
  if (permission && !appContext.user.login.permissions?.includes(permission)) return false;

  return true;
};

export const hasUserRolePermission = (appContext: IAppContext, permission: PermissionTypes): boolean => {
  //When the user has an admin roles, role permissions do not apply
  if (hasAdminRole(appContext.user.login.userSystemRoles)) return true;
  if (denyPermissionsForSystemRoles(permission, appContext.user.login.userSystemRoles)) return false;

  return appContext.user.login.permissions?.includes(permission) ?? false;
};

const denyPermissionsForSystemRoles = (permission: PermissionTypes, systemRoles: number[] | undefined): boolean => {
  if (!systemRoles || systemRoles.length === 0) return true;

  switch (permission) {
    case PermissionTypes.ModuleAdmin:
    case PermissionTypes.ModuleLibrary:
    case PermissionTypes.ModuleOrg:
    case PermissionTypes.ModuleRequirements:
    case PermissionTypes.ModuleRisks:
    case PermissionTypes.ModuleControls:
    case PermissionTypes.ModuleAnnualPlan:
    case PermissionTypes.ModuleTasks:
    case PermissionTypes.ModuleStandards:
    case PermissionTypes.ModuleAssets:
    case PermissionTypes.ModulePartner:
    case PermissionTypes.CreateTask:
    case PermissionTypes.CreateLibraryItem:
      return false;
    default:
      return !hasRoleFeature(systemRoles, FeatureTypes.GenericManager);
  }
};

export const convertAuthSchemaString = (schemas: string | undefined): (number | undefined)[] => {
  //From the back-end, for some APIs, we get a comma seperated string with authSchema Ids
  //1. In this string, '0' and 'undefined' must be translated to undefined.
  //2. When the string is empty, return 1 item with value undefined
  //3. Ignore any invalid authSchema Ids

  let authSchemas: (number | undefined)[] = [];

  if (schemas) {
    const items = schemas.split(',');
    items.forEach((item) => {
      const id = Number(item === 'undefined' ? 0 : item);
      if (!isNaN(id)) {
        authSchemas.push(id === 0 ? undefined : id);
      }
    });
  } else {
    authSchemas.push(undefined);
  }

  return authSchemas;
};

export const hasUserDataPermission = (
  appContext: IAppContext,
  authSchemaIds: (number | undefined)[],
  operation: AuthSchemaLineOperation,
) => {
  if (authSchemaIds.length === 0) return true;
  //when user has no other system role, user is always read-only
  if (!hasUserFeatureGenericManager(appContext)) return false;
  //when subscription is basic, user has all permissions
  if (appContext.user.login.subscriptionType === SubscriptionTypes.Basic) return true;
  //When the user has an admin roles, data permissions do not apply
  if (hasAdminRole(appContext.user.login.userSystemRoles)) return true;

  //check the permissions
  const userId = appContext.user.id;
  if (authSchemaIds.length === 1) {
    if (authSchemaIds[0] === undefined) return true;

    return appContext.globalDataCache.authSchemas.get(authSchemaIds[0]).hasLine(userId, operation);
  } else {
    //when multiple schemas are provided, return true when the user has permissions on 1+ of them
    if (authSchemaIds.includes(undefined)) return true;

    //create list of unique schemas without undefined
    const unique = [...new Set(authSchemaIds)].filter((a) => a !== undefined) as number[];
    //get scchemas from cache
    const schemas = appContext.globalDataCache.authSchemas.getItemsForId(unique);

    return schemas.some((a) => a.hasLine(userId, operation));
  }
};

export const getStandardElementAuthSchemaIds = (
  items: (Control | Theme)[] | Control | Theme | undefined,
  appContext: IAppContext,
): (number | undefined)[] => {
  if (!items) return [];
  let elements: (Control | Theme)[];
  if (items instanceof Control || items instanceof Theme) {
    elements = [items];
  } else {
    elements = items;
  }

  const result: (number | undefined)[] = [];

  for (let idx = 0; idx < elements.length; idx++) {
    const element = elements[idx];
    const schemas = element.getAutSchemaIds(appContext);
    result.push(...schemas);
  }

  return result;
};
