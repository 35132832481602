import {
  Callout,
  ICalloutContentStyles,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Link,
  ComboBox,
  HoverCard,
  HoverCardType,
  DirectionalHint,
  IPlainCardProps,
  Text,
  Label,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import { AuthSchemaInfo } from 'components/Auth/AuthSchemaInfo';
import AuthSchemaList from 'components/Auth/AuthSchemaList';
import WarningMessage from 'components/Notification/WarningMessage';
import { globalStackTokensGapSmall } from 'globalStyles';
import { AuthSchema } from 'models/auth/authSchema';
import { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { apiGetAuthSchemasForFilter } from 'services/Api/authService';
import { apiRequest } from 'services/Auth/authConfig';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { sortOnString } from 'utils/sorting';

interface IEventFormAuthSchemaPickerProps {
  selectedAuthSchemaId: number | undefined;
  disabled?: boolean;
  label?: string;
  hideMembersOnHover?: boolean;
  showForUserId?: string;
  isOwner?: boolean;
  overrideAdmin?: boolean;
  onSelect: (item: AuthSchema | undefined) => void;
}

const AuthSchemaPicker = (props: IEventFormAuthSchemaPickerProps) => {
  const { t } = useTranslation(['translation', 'adminAuth']);
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [authSchemas, setAuthSchemas] = useState<AuthSchema[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedAuthSchema, setSelectedAuthSchema] = useState<AuthSchema | undefined>(undefined);
  const [currentAuthSchema, setCurrentAuthSchema] = useState<AuthSchema | undefined>(undefined);
  const disabledCalc = (!appContext.user.isAdmin() || props.disabled) && !props.overrideAdmin;
  const url = '/admin/users/permissions';

  //
  // Effects
  //
  useEffect(() => {
    if (isOpen) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    //for displaying the current schema, use the global data cache
    //the cache always contains the schema that is linked to an entity that the user see.
    if (props.selectedAuthSchemaId) {
      setCurrentAuthSchema(appContext.globalDataCache.authSchemas.get(props.selectedAuthSchemaId));
    } else {
      setCurrentAuthSchema(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedAuthSchemaId]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      setSelectedAuthSchema(undefined);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let schemas = await apiGetAuthSchemasForFilter(props.showForUserId, accessToken);
      schemas.sort((a, b) => sortOnString(a.name, b.name));
      setAuthSchemas(schemas);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const calloutStyle: Partial<ICalloutContentStyles> = {
    root: {
      padding: '10px 12px',
      minHeight: 300,
      height: '60vh',
      maxHeight: '80vh',
      minWidth: 250,
      width: '40vw',
      maxWidth: '80vw',
    },
  };

  const navigateToAuthSchemas = () => {
    history.push(url);
  };

  const onSelect = (item: AuthSchema | undefined) => {
    props.onSelect(item);
    setCurrentAuthSchema(item);
    setIsOpen(false);
  };

  const getButtonText = (): string => {
    if (selectedAuthSchema) {
      return t('translation:General.Button.Select');
    }
    if (props.selectedAuthSchemaId) {
      return t('translation:General.Button.Clear');
    } else {
      return t('translation:General.Button.Close');
    }
  };

  const onRenderSchemaInfo = (item: AuthSchema | undefined): JSX.Element | null => {
    if (!item) return null;

    return (
      <Stack styles={{ root: { padding: 20, minHeight: 300, minWidth: 250, height: '30vh', width: '50vw' } }}>
        <AuthSchemaInfo authSchemaId={item.authSchemaId} showNoMemberMsg={true} />
      </Stack>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderSchemaInfo,
    renderData: currentAuthSchema,
    directionalHint: DirectionalHint.topCenter,
  };

  const showCallout = () => {
    if (!disabledCalc) setIsOpen(true);
  };

  const getNoDataLink = () => {
    if (isLoading || authSchemas.length > 0) return null;

    if (window.location.pathname !== url) {
      if (appContext.user.isAdmin() && !props.showForUserId) {
        return (
          <Stack verticalFill verticalAlign="center" horizontalAlign="center">
            <Link onClick={() => navigateToAuthSchemas()}>{t('adminAuth:AuthSchemaPicker.NoDataLinkAdmin')}</Link>
          </Stack>
        );
      }

      if (props.showForUserId) {
        return (
          <Stack verticalFill verticalAlign="center" horizontalAlign="center">
            <Text>
              {t('adminAuth:AuthSchemaPicker.NoDataLinkUser', {
                name: appContext.globalDataCache.users.get(props.showForUserId).name,
              })}
            </Text>
          </Stack>
        );
      }
    }

    return (
      <Stack verticalFill verticalAlign="center" horizontalAlign="center">
        <Text>{t('adminAuth:AuthSchemaPicker.NoDataLink')}</Text>
      </Stack>
    );
  };

  //
  // Main render
  //
  if (!hasUserFeature(appContext, FeatureTypes.RBAC)) {
    return null;
  }

  //Show warning if the user is not an owner and does not have access to the schema.
  //The global data cache always contains the calculated lines for the current user.
  const isNoOwnerOrAdminOrRead =
    selectedAuthSchema &&
    !props.isOwner &&
    !appContext.user.isAdmin() &&
    !appContext.globalDataCache.authSchemas.get(selectedAuthSchema.authSchemaId).hasLine(appContext.user.id);

  return (
    <Fragment>
      <HoverCard
        type={HoverCardType.plain}
        plainCardProps={plainCardProps}
        shouldBlockHoverCard={() => props.hideMembersOnHover || currentAuthSchema === undefined}
      >
        <ComboBox
          id="authschema-target"
          text={currentAuthSchema?.name ?? t('adminAuth:AuthSchemaPicker.Placeholder')}
          options={[]}
          onMenuOpen={showCallout}
          onKeyDown={showCallout}
          onKeyDownCapture={showCallout}
          disabled={disabledCalc}
          placeholder={t('adminAuth:AuthSchemaPicker.Placeholder')}
          label={props.label ?? t('adminAuth:AuthSchemaPicker.Label')}
          styles={{ root: { minWidth: 150 } }}
        />
      </HoverCard>
      <Callout
        styles={calloutStyle}
        hidden={!isOpen}
        setInitialFocus
        gapSpace={0}
        beakWidth={0}
        target={'#authschema-target'}
        onDismiss={() => setIsOpen(false)}
      >
        <Stack verticalFill tokens={globalStackTokensGapSmall}>
          {getNoDataLink()}
          {isLoading && (
            <Stack verticalFill horizontalAlign="center" verticalAlign="center">
              <Spinner size={SpinnerSize.large} />
            </Stack>
          )}
          {!isLoading && authSchemas.length > 0 && props.showForUserId && (
            <Stack.Item>
              <Label>
                {t('adminAuth:AuthSchemaPicker.FilterUserInfo', {
                  name: appContext.globalDataCache.users.get(props.showForUserId).name,
                })}
              </Label>
            </Stack.Item>
          )}
          {!isLoading && authSchemas.length > 0 && (
            <Stack.Item grow>
              <AuthSchemaList
                authSchemas={authSchemas}
                onSelectAuthSchema={(item) => {
                  setSelectedAuthSchema(item);
                }}
                onChooseAuthSchema={(item) => {
                  onSelect(item);
                }}
                isLoading={isLoading}
                showSearch={true}
              />
            </Stack.Item>
          )}
          {selectedAuthSchema && !appContext.isMobileView && (
            <Stack.Item>
              <AuthSchemaInfo
                authSchemaId={selectedAuthSchema.authSchemaId}
                showNoMemberMsg={!isNoOwnerOrAdminOrRead}
              />
            </Stack.Item>
          )}
          {isNoOwnerOrAdminOrRead && <WarningMessage message={t('adminAuth:AuthSchemaPicker.NoMemberWarning')} />}
          <Separator />
          <Stack.Item>
            <PrimaryButton
              onClick={() => {
                onSelect(selectedAuthSchema);
              }}
            >
              {getButtonText()}
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      </Callout>
    </Fragment>
  );
};

export default AuthSchemaPicker;
